import styled from 'styled-components'
import { BlockTitlePrimaryContainer, Text } from '../../components/TitlePrimary/styled'
import { DiagramChange, DiagramForm, DiagramHead, WorkContainer, WorkWrapper } from '../Work/styled'
import { PathDiagramContainer } from '../Work/Diagram/Path/styled'
import { BlockDiagramContainer } from '../Work/Diagram/Block/styled'

export const WorkDefAiContainer = styled.div`
  position: relative;
  padding-top: 180px;
  margin-bottom: 250px;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 1152px;
    height: 912px;
    transform: translateX(-50%);
    background: radial-gradient(
      68.15% 54.93% at 42.91% 49.98%,
      #fff 0%,
      #ffd2ee 30.28%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.05;
    filter: blur(123px);
    pointer-events: none;
  }
  ${WorkContainer} {
    width: max-content;
  }
  ${BlockTitlePrimaryContainer} {
    .title-big {
      p {
        flex-wrap: unset;
      }
    }
    ${Text} {
      text-align: center;
      line-height: 24px;
    }
  }
  ${DiagramHead} {
    margin-left: 24px;
  }
  ${DiagramChange} {
    position: relative;
    z-index: 5;
    &.custom {
      margin-top: -40px;
      left: 4px;
      margin-bottom: -9px;
    }
  }
  ${DiagramForm} {
    max-width: 700px;
    & > div {
      padding: 12px;
    }
  }
  @media screen and (max-width: 1023px) {
    &::before {
      content: none;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    ${DiagramForm} {
      max-width: 656px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    ${DiagramHead} {
      margin-left: 0;
      left: -155px;
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 50px;
    margin-bottom: 100px;
    ${WorkContainer} {
      width: auto;
      & > figure {
        width: calc(100% - 10px);
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`
export const DiagramIntent = styled.div`
  position: relative;
  max-width: 600px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #858585;
  backdrop-filter: blur(11px);
  opacity: 0;
  ${PathDiagramContainer} {
    position: absolute;
    left: -52px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -20%;
  }
  &.appear {
    animation: fadeBlock 0.5s linear forwards;
    animation-delay: 2.2s;
  }
`
export const IntentForm = styled.div`
  h2 {
    color: #fff;
    text-align: center;
    font-family: Tektur;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
    opacity: 0;
  }
  &.appear {
    h2 {
      animation: fadeBlock 0.5s linear forwards;
      animation-delay: 2.5s;
    }
  }
`
export const IntentFormInner = styled.div`
  &.appear {
    h3 {
      animation: fadeBlock 0.5s linear forwards;
      animation-delay: 2.6s;
    }
  }
  h3 {
    color: #ff3556;
    text-align: center;
    font-family: 'Wix Madefor Text';
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 10px;
    opacity: 0;
  }
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    & > li {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 8px 20px;
      min-height: 48px;
      width: calc(50% - 5px);
      border-radius: 4px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(153, 153, 153, 0.15) 100%
      );
      box-shadow: 0px 3.765px 23.247px 0px rgba(255, 255, 255, 0.25) inset;
      backdrop-filter: blur(5px);
      p {
        color: #fff;
        font-family: 'Wix Madefor Text';
        font-size: 13px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
`
export const IntentItemSolver = styled.li<{ delay?: any }>`
  opacity: 0;
  animation: fadeBlock 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: ${({ delay }) => delay};
`
export const IntentInnerItem = styled.li<{ delay?: any }>`
  opacity: 0;
  animation: fadeBlock 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: ${({ delay }) => delay};
`
export const IntentFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 15px;
  &.appear {
    h3 {
      animation: fadeBlock 0.5s linear forwards;
      animation-delay: 1.5s;
    }
  }
`
export const IntentFormSolver = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    color: #ff3556;
    text-align: center;
    font-family: 'Wix Madefor Text';
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 10px;
    opacity: 0;
  }
  ul {
    display: flex;
    gap: 8px;
    & > li {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
`
export const BrainWrapper = styled.div`
  position: relative;
  left: -46px;
  ${PathDiagramContainer} {
    position: absolute;
    top: -98px;
    left: 50%;
    transform: translateX(-50%);
  }
  & > figure {
    opacity: 0;
    &.opacity {
      animation: opacityText 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
      animation-delay: 1.4s;
    }
  }
`
export const DiagramIntentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 45px;
  max-width: 1080px;
  margin-left: auto;
  left: 126px;
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    flex-direction: column;
    left: 39px;
  }
`
export const DiagramOutput = styled.div`
  display: flex;
  align-items: center;
  ${PathDiagramContainer} {
    position: relative;
    z-index: 5;
    p {
      top: 80px;
      left: -10px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    flex-direction: column;
    width: max-content;
    margin-top: -44px;
    padding-left: 28px;
    ${PathDiagramContainer} {
      transform: rotate(90deg);
      p {
        transform: rotate(-90deg);
        top: 70px;
      }
    }
  }
`
export const OutputInner = styled.div`
  position: relative;
  left: -4px;
  ${BlockDiagramContainer} {
    flex-direction: column;
    width: 180px;
    min-height: 88px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    figure {
      width: 25px;
      height: 25px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    display: flex;
    gap: 10px;
    margin-top: -50px;
    ${BlockDiagramContainer} {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
`

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const convertFixed = (value: any) => {
  if (!Number(value)) return 0
  if (Number(value) === 0) return value?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else if (Number(value) > 1e3) return value?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else if (Number(value) < 1 && Number(value) >= 9e-4) return value?.toFixed(4)
  else if (Number(value) < 9e-4 && Number(value) >= 9e-7) return value?.toFixed(7)
  else return value.toFixed(2)
}

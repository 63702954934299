export const ICONS = [
    "bg-join-c.png",
  "flow_work.png",
  "flow_work_defi.png",
  "footer_icon_01.svg",
  "footer_text_01.svg",
  "footer_text_02.svg",
  "footer_text_03.svg",
  "footer_text_04.svg",
  "footer_text_05.svg",
  "footer_text_06.svg",
  "future_bg_01.png",
  "future_decor_01.png",
  "future_img_01.png",
  "future_img_02.png",
  "future_img_03.png",
  "future_img_04.svg",
  "future_img_05.svg",
  "future_img_06.png",
  "future_img_07.png",
  "future_img_08.png",
  "future_img_09.svg",
  "future_img_10.png",
  "icn_arrow.svg",
  "icn_arrow_02.svg",
  "icn_bot.png",
  "icn_chat.svg",
  "icn_docs.svg",
  "icn_dot_black.svg",
  "icn_dot_red.svg",
  "icn_form_01.svg",
  "icn_form_02.svg",
  "icn_form_03.svg",
  "icn_function_01.svg",
  "icn_function_02.svg",
  "icn_function_03.svg",
  "icn_function_04.svg",
  "icn_function_05.svg",
  "icn_line_red.svg",
  "icn_line_white.svg",
  "icn_pitchd.svg",
  "icn_swap.svg",
  "icn_voice.svg",
  "iconKIB.png",
  "icon_0x.png",
  "icon_1inch.png",
  "icon_block_1.svg",
  "icon_block_answer.svg",
  "icon_block_sign.svg",
  "icon_block_user.svg",
  "icon_brain.png",
  "icon_bridge_1.png",
  "icon_bridge_2.png",
  "icon_bridge_3.png",
  "icon_bridge_4.png",
  "icon_curve.png",
  "icon_defi_1.png",
  "icon_defi_2.png",
  "icon_discord_01.svg",
  "icon_discord_02.svg",
  "icon_head.png",
  "icon_intent_balance.svg",
  "icon_intent_market.svg",
  "icon_intent_transfer.svg",
  "icon_intent_wallet.svg",
  "icon_kyber.png",
  "icon_loop.png",
  "icon_matcha.png",
  "icon_medium.svg",
  "icon_para.png",
  "icon_serum.png",
  "icon_sling.png",
  "icon_sushi.png",
  "icon_telegram.svg",
  "icon_twitter.svg",
  "icon_uni.png",
  "icon_youtube.svg",
  "img-corner-box.png",
  "img-corner-light.png",
  "img-dot-chart.png",
  "img-dot.png",
  "img-k-red.png",
  "img-k.png",
  "img-light-red.png",
  "img-line-b.png",
  "img-line-chart.png",
  "img-line-l.png",
  "img-line-r.png",
  "img-line-red.png",
  "img-line-t.png",
  "img-swap-frame.png",
  "img-swap-token.png",
  "img-swap.png",
  "kevisual_img_02.png",
  "keyisual_img_01.png",
  "keyvisual_bg_01.svg",
  "keyvisual_bg_02.svg",
  "keyvisual_bg_03.png",
  "keyvisual_bg_04.png",
  "keyvisual_bg_05.png",
  "keyvisual_icn_deck.svg",
  "keyvisual_icn_docs.svg",
  "keyvisual_icon_01.png",
  "keyvisual_img_02.png",
  "keyvisual_img_03.png",
  "keyvisual_img_04.png",
  "keyvisual_parner_01.png",
  "keyvisual_parner_01_active.png",
  "keyvisual_parner_02.png",
  "keyvisual_parner_02_active.png",
  "keyvisual_parner_03.png",
  "keyvisual_parner_03_active.png",
  "keyvisual_parner_04.png",
  "keyvisual_parner_04_active.png",
  "keyvisual_parner_05.png",
  "keyvisual_parner_05_active.png",
  "keyvisual_parner_06.png",
  "keyvisual_parner_06_active.png",
  "keyvisual_parner_07.png",
  "keyvisual_parner_07_active.png",
  "keyvisual_parner_08.png",
  "keyvisual_parner_08_active.png",
  "keyvisual_parner_10.png",
  "keyvisual_parner_10_active.png",
  "keyvisual_parner_11.png",
  "keyvisual_parner_11_active.png",
  "keyvisual_parner_12.png",
  "keyvisual_parner_12_active.png",
  "keyvisual_parner_13.png",
  "keyvisual_parner_13_active.png",
  "keyvisual_parner_14.png",
  "keyvisual_parner_14_active.png",
  "kibble_logo.png",
  "work_bg.png"
] as const;

export type IconType = (typeof ICONS)[number];

export const getIconPath = (icon: IconType) => `/assets/images/${icon}`;

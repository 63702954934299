import styled from 'styled-components'

export const ChatWrapper = styled.div`
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(27, 27, 27, 0.1) 100%);
  backdrop-filter: blur(122.73950958251953px);
  padding: 59.255px 9.511px 9.511px 9.511px;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:after {
    position: absolute;
    content: '';
    width: 408.956px;
    height: 828.08px;
    transform: rotate(135.544deg);
    right: -171.679px;
    top: -465.188px;
    border-radius: 828.08px;
    opacity: 0.22;
    background: #fff;
    filter: blur(200px);
    pointer-events: none;
  }
  .title-chat {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 25.362px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 25.362px */
    span {
      display: block;
    }
  }
  .text-chat {
    color: rgba(255, 255, 255, 0.75);
    font-family: 'Wix Madefor Text';
    font-size: 12.681px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 15.851px */
    margin-top: 10px;
    margin-bottom: 25px;
    max-width: 300px;
  }
  .title-chat {
    font-size: 24px;
    line-height: 100%;
    span {
      font-size: 40px;
      line-height: 100%;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 10px;
    min-height: 600px;
    /* border-radius: 0;
    background: unset;
    backdrop-filter: unset;
    min-height: unset;
    border: unset; */
    &:after {
      content: none;
    }
    .text-chat {
      margin-bottom: 15px;
    }
    .title-chat {
      span {
        font-size: 25px;
      }
    }
    .swiper-slide {
      width: 60%;
      padding: 16px;
      border-radius: 24px;
      background: #525252;
      box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.08) inset;
    }
    .button-generate {
      width: 100%;
      border-radius: 500px;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      font-size: 16px;
      line-height: 130%; /* 20.8px */
      padding: 9px 2px 7px;
      margin-top: 16px;
      span {
        position: relative;
        padding-left: 20px;
        &:before {
          position: absolute;
          content: '';
          top: 50%;
          margin-top: -8px;
          left: 0;
          background: url('/assets/images/icn_generate.svg') center / contain no-repeat;
          width: 16px;
          height: 16px;
        }
      }
    }
    .block-chat {
      overflow-y: auto;
      /* height: calc(100vh + 600px); */
    }
  }
`
export const Listfunction = styled.ul`
  display: flex;
  gap: 12px;
  position: relative;
  z-index: 99;
  .item-function {
    padding: 16px;
    border-radius: 24px;
    background: #525252;
    box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.08) inset;
    cursor: pointer;
    transition: opacity 0.26s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  .icon {
    margin-bottom: 10px;
    border-radius: 50%;
    overflow: hidden;
  }
  .title {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 16px;
    font-weight: 600;
    line-height: 130%; /* 16.485px */
  }
  .text {
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Wix Madefor Text';
    font-size: 14px;
    line-height: 130%; /* 14.424px */
  }
  @media screen and (max-width: 767px) {
    .title {
      font-size: 13px;
      margin-bottom: 5px;
    }
    .text {
      font-size: 10px;
    }
    margin-bottom: 20px;
    flex-wrap: wrap;
    .item-function {
      padding: 12px;
    }
    li {
      width: calc(50% - 6px);
      &:last-child {
        width: 100%;
      }
      img {
        width: 35px;
        height: 32px;
      }
    }
  }
`

export const ChatContainer = styled.div`
  max-width: 596px;
  width: 100%;
  position: relative;
  border-radius: 32px;
  transform-style: preserve-3d;
  will-change: transform;
  /* pointer-events: none; */
  &::before,
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
  }
  &::before {
    width: 552.645px;
    height: 604.171px;
    transform: rotate(150.635deg);
    border-radius: 604.171px;
    opacity: 0.87;
    background: linear-gradient(190deg, #cfd0da 34.26%, rgba(207, 208, 218, 0) 92.38%);
    filter: blur(150px);
    left: -293px;
    top: -269px;
  }
  &:after {
    width: 295.804px;
    height: 404.71px;
    right: 143.822px;
    top: -198.451px;
    border-radius: 404.71px;
    opacity: 0.33;
    background: linear-gradient(23deg, rgba(255, 164, 164, 0) 8.72%, #f00 83.03%);
    filter: blur(150px);
  }
  &.guide {
    &:before,
    &::after {
      content: none;
    }
    ${ChatWrapper} {
      padding-top: 20px;
      &:after {
        content: none;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }

  @media screen and (max-width: 767px) {
    &::before {
      width: 100%;
      left: 0;
    }
  }
`

export const FrameShowChat = styled.div`
  overflow-y: scroll;
  /* height: 400px; */
  width: 100%;
  background-color: transparent;
  padding: 20px 0;
  border-radius: 16px;
  /* pointer-events: none; */
  @media screen and (max-width: 767px) {
    padding: 0 0 20px;
  }
`

export const BlockChat = styled.div`
  border-radius: 24px;
  background: #303030;
  padding: 12px;
  width: 100%;
  margin-top: auto;
  .block-textarea {
    border-radius: 16px;
    background: #000;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08) inset;
    padding: 8px;
    height: 105px;
    position: relative;
    margin-bottom: 12px;
    textarea {
      padding: 0 36px 0 16px;
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      color: #fff;
      background-color: transparent;
    }
  }
  .icon-chat {
    position: absolute;
    top: 3px;
    right: 0;
    opacity: 0.5;
    transition: opacity 0.26s ease;
    &.active {
      opacity: 1;
    }
  }
  .block-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-form {
    display: flex;
    align-items: center;
    gap: 8px;
    li {
      border-radius: 50px;
      background: #000;
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 5px 15px 3px 8px;
      cursor: pointer;
      transition: opacity 0.26s ease;
      &:hover {
        opacity: 0.7;
      }
      span {
        color: var(--New, #e84142);
        font-size: 16px;
        line-height: 130%;
        font-family: 'Wix Madefor Text';
      }
      &:last-child {
        background: rgba(255, 255, 255, 0.1);
        span {
          color: #fff;
          font-size: 16px;
          line-height: 130%;
          opacity: 0.5;
        }
      }
    }
  }
  .block-voice {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    cursor: pointer;
    transition: opacity 0.26s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  @media screen and (max-width: 768px) {
  }
`

export const ContentChatUser = styled.div`
  width: 100%;
  margin: 16px 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  animation: chatUp 1s ease;
  position: relative;
  p {
    width: max-content;
    border-radius: 16px 4px 16px 16px;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.1) inset;
    padding: 12px 15px;
    text-align: right;
  }
  @keyframes chatUp {
    from {
      transform: translateY(10px);
    }
    to {
      transform: translateY(0px);
    }
  }
  @media screen and (max-width: 767px) {
    p {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`

export const ButtonChatMobile = styled.div`
  border-radius: 38.785px;
  background: rgba(196, 177, 255, 0.08);
  backdrop-filter: blur(4.977419853210449px);
  padding: 6px;
  position: fixed;
  bottom: 100px;
  left: 50%;
  margin-left: -117px;
  z-index: 1;
  button {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-family: 'Wix Madefor Text';
    letter-spacing: -0.16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 334.736px;
    border: 0.97px solid #fff;
    background: radial-gradient(43.11% 181.04% at 50% 50%, #e84142 0%, #ad0d0e 100%);
    box-shadow: 0px 0px 57px -7.757px rgba(255, 57, 74, 0.5),
      0px 7.757px 15.514px -7.757px rgba(255, 57, 74, 0.12),
      0px 34.906px 31.028px -15.514px rgba(255, 57, 74, 0.21), 0px 0px 11.635px 0px #fff inset,
      0px -23.271px 31.028px 0px rgba(255, 255, 255, 0.24) inset,
      0px 7.757px 23.271px -15.514px rgba(255, 255, 255, 0.24) inset;
    height: 54.299px;
    padding: 15.514px 62.056px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
  }
`

export const BlockRecent = styled.div`
  margin-bottom: 32px;
  .recent-top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .title-recent {
    color: #fff;
    font-size: 24px;
    line-height: 100%; /* 24px */
    font-family: 'Wix Madefor Text';
  }
  .see-more {
    color: var(--New, #e84142);
    font-size: 15px;
    font-family: 'Wix Madefor Text';
    line-height: 100%; /* 15px */
  }
  .list-recent {
    li {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 16px;
      background: #333232;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    .content-left {
      display: flex;
      align-items: flex-start;
      width: 88%;
      gap: 12px;
      img {
        flex-shrink: 0;
      }
      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
`

export const BlockBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 0;
  .back,
  .setting {
    flex-shrink: 0;
    border-radius: 27.494px;
    background: #000;
    box-shadow: 0px 0px 5.865px 0px rgba(0, 0, 0, 0.15) inset;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .back {
    img {
      transform: scale(-1);
    }
  }
  .setting {
    p {
      position: relative;
      top: -4px;
    }
  }
`

import { DiagramForm } from '../../styled'
import FormItem from './Item'

const DiagramForms = ({ data, title, className, delay, skeleton }: any) => {
  return (
    <DiagramForm className={className} delay={delay}>
      <h2>{title}</h2>
      {data.map((item: any, index: any) => {
        return <FormItem key={index} item={item} skeleton={skeleton} />
      })}
    </DiagramForm>
  )
}

export default DiagramForms

import './future.css'
const LineDefi = ({ isAnimationDefi }: any) => {
  return (
    <svg
      width="606"
      height="512"
      viewBox="0 0 606 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M352.713 188.126V186.011C352.713 170.547 365.249 158.011 380.713 158.011H488.09C503.554 158.011 516.09 145.475 516.09 130.011V19.4805"
          stroke="url(#paint0_linear_10006_3263)"
        />
        <path
          d="M252.447 188.126V186.011C252.447 170.547 239.911 158.011 224.447 158.011H117.07C101.606 158.011 89.0705 145.475 89.0705 130.011V19.4805"
          stroke="url(#paint1_linear_10006_3263)"
        />
        <path
          d="M252.459 306.946V309.061C252.459 324.525 239.923 337.061 224.459 337.061H117.082C101.618 337.061 89.0822 349.597 89.0822 365.061V503.852"
          stroke="url(#paint2_linear_10006_3263)"
        />
        <path
          d="M352.725 306.947V309.062C352.725 324.526 365.261 337.062 380.725 337.062H488.101C503.565 337.062 516.101 349.598 516.101 365.062V498.062"
          stroke="url(#paint3_linear_10006_3263)"
        />
        <path
          d="M279.781 329.781L279.781 511.82"
          stroke="url(#paint4_linear_10006_3263)"
          className={`line-defi-up ${isAnimationDefi ? 'active-line-defi-up' : ''}`}
        />
        <path
          d="M315.711 329.781L315.711 511.82"
          stroke="url(#paint5_linear_10006_3263)"
          className={`line-defi-up line-defi-up-1 ${isAnimationDefi ? 'active-line-defi-up' : ''}`}
        />
        <path
          d="M290.289 329.781L290.289 511.82"
          stroke="url(#paint6_linear_10006_3263)"
          className={`line-defi-up line-defi-up-1 ${isAnimationDefi ? 'active-line-defi-up' : ''}`}
        />
        <path
          d="M326.219 329.781L326.219 511.82"
          stroke="url(#paint7_linear_10006_3263)"
          className={`line-defi-up ${isAnimationDefi ? 'active-line-defi-up' : ''}`}
        />
        <path
          d="M279.371 182.566L279.371 0.52735"
          stroke="url(#paint8_linear_10006_3263)"
          className={`line-defi-up ${isAnimationDefi ? 'active-line-defi-up' : ''}`}
        />
        <path
          d="M315.301 182.566L315.301 0.527344"
          stroke="url(#paint9_linear_10006_3263)"
          className={`line-defi-up line-defi-up-1 ${isAnimationDefi ? 'active-line-defi-up' : ''}`}
        />
        <path
          d="M289.879 182.566L289.879 0.527344"
          stroke="url(#paint10_linear_10006_3263)"
          className={`line-defi-up line-defi-up-1 ${isAnimationDefi ? 'active-line-defi-up' : ''}`}
        />
        <path
          d="M325.809 182.566L325.809 0.527344"
          stroke="url(#paint11_linear_10006_3263)"
          className={`line-defi-up ${isAnimationDefi ? 'active-line-defi-up' : ''}`}
        />
        <path
          d="M340.316 306.945V381.066C340.316 396.53 352.852 409.066 368.316 409.066H605.351"
          stroke="url(#paint12_linear_10006_3263)"
        />
        <path
          d="M265.352 306.945V381.066C265.352 396.53 252.816 409.066 237.352 409.066H0.649413"
          stroke="url(#paint13_linear_10006_3263)"
        />
        <path
          d="M340.316 182.566V108.445C340.316 92.9813 352.852 80.4453 368.316 80.4453H605.351"
          stroke="url(#paint14_linear_10006_3263)"
        />
        <path
          d="M265.352 182.566V108.445C265.352 92.9813 252.816 80.4453 237.352 80.4453H0.649413"
          stroke="url(#paint15_linear_10006_3263)"
        />
        <path
          d="M356.836 238.093H404.586C420.05 238.093 432.586 225.557 432.586 210.093V31.2363"
          stroke="url(#paint16_linear_10006_3263)"
        />
        <path
          d="M352.723 261.767H404.586C420.05 261.767 432.586 274.303 432.586 289.767L432.586 502.607"
          stroke="url(#paint17_linear_10006_3263)"
        />
        <path
          d="M248.754 238.093H201.004C185.54 238.093 173.004 225.557 173.004 210.093V31.2363"
          stroke="url(#paint18_linear_10006_3263)"
        />
        <path
          d="M252.867 261.767H201.004C185.54 261.767 173.004 274.303 173.004 289.767L173.004 502.607"
          stroke="url(#paint19_linear_10006_3263)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10006_3263"
          x1="434.401"
          y1="19.4805"
          x2="434.401"
          y2="188.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10006_3263"
          x1="170.759"
          y1="19.4805"
          x2="170.759"
          y2="188.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10006_3263"
          x1="170.771"
          y1="475.592"
          x2="170.771"
          y2="306.945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.45" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10006_3263"
          x1="434.413"
          y1="498.063"
          x2="434.413"
          y2="306.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.45" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_10006_3263"
          x1="280.281"
          y1="329.781"
          x2="280.281"
          y2="494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_10006_3263"
          x1="316.211"
          y1="329.781"
          x2="316.211"
          y2="485.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_10006_3263"
          x1="290.789"
          y1="329.781"
          x2="290.789"
          y2="492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_10006_3263"
          x1="326.719"
          y1="329.781"
          x2="326.719"
          y2="490.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_10006_3263"
          x1="279.871"
          y1="182.566"
          x2="279.871"
          y2="0.999993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_10006_3263"
          x1="315.801"
          y1="182.566"
          x2="315.801"
          y2="2.50001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_10006_3263"
          x1="290.379"
          y1="182.566"
          x2="290"
          y2="1.00001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_10006_3263"
          x1="326.309"
          y1="182.566"
          x2="326.309"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_10006_3263"
          x1="330.028"
          y1="303.14"
          x2="592.726"
          y2="400.696"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.5" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_10006_3263"
          x1="275.627"
          y1="303.14"
          x2="13.1782"
          y2="400.481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.5" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_10006_3263"
          x1="330.028"
          y1="186.372"
          x2="592.726"
          y2="88.816"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.5" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_10006_3263"
          x1="275.627"
          y1="186.372"
          x2="13.1782"
          y2="89.0307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.5" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_10006_3263"
          x1="359.133"
          y1="238.094"
          x2="504.896"
          y2="147.211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_10006_3263"
          x1="355.144"
          y1="261.768"
          x2="520.357"
          y2="382.142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_10006_3263"
          x1="246.457"
          y1="238.094"
          x2="103.533"
          y2="144.953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_10006_3263"
          x1="250.446"
          y1="261.768"
          x2="85.2328"
          y2="382.142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LineDefi

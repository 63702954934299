import styled from 'styled-components'

export const WrapWhoWeAre = styled.div`
  position: relative;
  padding-bottom: 150px;
  padding-top: 100px;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
`

export const WrapImgChart = styled.div`
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  .img-line-chart {
    max-width: 1100px;
    position: absolute;
    top: -275px;
    pointer-events: none;
    svg {
      border-radius: 50%;
    }
    img {
      width: 100%;
    }
    .line-red {
      stroke-dasharray: 1600;
      stroke-dashoffset: -1600;
    }
  }
  @media screen and (max-width: 768px) {
    .img-line-chart {
      top: -140px;
      svg {
        width: 100%;
      }
      .line-red {
        stroke-dasharray: 750;
        stroke-dashoffset: 750;
      }
    }
  }

  @keyframes lineUp {
    from {
      stroke-dashoffset: -1600;
    }
    to {
      stroke-dashoffset: -750;
    }
  }
`

export const WrapChart = styled.div`
  position: relative;
  align-items: flex-start;
  width: 100%;
  @media screen and (max-width: 768px) {
    &.row-center {
      display: block;
    }
  }
`

export const TitleWhoWeAre = styled.div`
  position: relative;
  max-width: 355px;
  h2 {
    color: #ff3556;
    text-align: left;
    font-family: Tektur;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
    .dot {
      width: 5.047px;
      height: 5.047px;
      border-radius: 1px;
      background: #ff3556;
      margin-right: 8px;
    }
  }

  h1 {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 64.674px; /* 101.053% */
    text-transform: capitalize;
  }

  p.des {
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Wix Madefor Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
    margin-bottom: 40px;
    margin-top: 16px;
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 35px;
    }
    p.des {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
`

export const ChartWhoWeAre = styled.div`
  position: relative;
  width: calc(100% - 355px);
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 19rem;
  }
`

export const ShowData = styled.div`
  position: relative;
  flex-wrap: wrap;
  gap: 180px 150px;
  margin-top: 330px;
  margin-right: 100px;
  width: calc((150px * 2) + 150px);
  .img-line-red {
    max-width: 1100px;
    left: -115px;
    position: absolute;
  }
  .img-k-red {
    max-width: 110px;
    position: absolute;
    left: 195px;
    z-index: 1;
    .img-line-top {
      width: 8px;
      height: 192px;
      position: absolute;
      top: -140px;
      left: 51px;
      z-index: 0;
    }
    .img-line-right {
      width: 270px;
      height: 8px;
      position: absolute;
      z-index: 0;
      left: 70px;
      top: 52px;
    }
    .img-line-bottom {
      width: 8px;
      height: 168px;
      position: absolute;
      z-index: 0;
      top: 85px;
      left: 51px;
    }
    .img-line-left {
      width: 761px;
      height: 8px;
      position: absolute;
      z-index: 0;
      top: 52px;
      right: 74px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 0;
    gap: 0;
    justify-content: space-between;
    .img-k-red {
      left: 50%;
      margin-left: -55px;
    }
  }
`

export const ItemData = styled.div`
  position: relative;
  width: 150px;
  h2 {
    color: #fff;
    text-align: left;
    font-family: 'Wix Madefor Text';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    text-transform: capitalize;
    margin-bottom: 20px;
    white-space: nowrap;
  }
  h1 {
    color: #fff;
    font-family: Tektur;
    font-size: 55px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 75% */
    text-transform: capitalize;
  }
  @media screen and (max-width: 767px) {
    width: calc(100% / 2);
    &:nth-child(2n + 1) {
      h2,
      h1 {
        text-align: right;
      }
    }
    h2 {
      font-size: 14px;
      margin-bottom: 0;
      white-space: unset;
    }
    h1 {
      font-size: 30px;
    }
  }
`

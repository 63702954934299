import './itemlaunch.css'
const ImageBgLaunch = ({ isAnimationDefi }: any) => {
  return (
    <svg
      width="569"
      height="527"
      viewBox="0 0 569 527"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.25">
        <path
          d="M249.145 522.891V507.813C249.145 492.349 261.681 479.813 277.145 479.813H292.504C307.968 479.813 320.504 467.277 320.504 451.813V308.303"
          stroke="url(#paint0_linear_10009_3958)"
        />
        <path
          d="M331.162 143.85H364.553C380.017 143.85 392.553 131.314 392.553 115.85V0.904297"
          stroke="url(#paint1_linear_10009_3958)"
          stroke-width="0.9152"
        />
        <path
          d="M0.783203 143.85H531.16"
          stroke="url(#paint2_linear_10009_3958)"
          stroke-width="0.9152"
        />
        <circle cx="73.6602" cy="143.35" r="7.5" fill="#D9D9D9" />
        <circle cx="248.66" cy="224.35" r="7.5" fill="#D9D9D9" />
        <circle cx="171.66" cy="143.35" r="7.5" fill="#D9D9D9" />
        <circle cx="392.66" cy="61.3496" r="7.5" fill="#D9D9D9" />
        <path
          d="M171.66 136.35V17.3496"
          stroke="url(#paint3_linear_10009_3958)"
          className={`line-normal ${isAnimationDefi ? 'line-active' : ''}`}
        />
        <rect x="467.16" y="395.85" width="15" height="15" rx="2" fill="#D9D9D9" />
        <rect
          x="310.867"
          y="143.457"
          width="14"
          height="14"
          rx="1.5"
          transform="rotate(-45 310.867 143.457)"
          stroke="white"
          className={`background-normal ${isAnimationDefi ? 'background-active' : ''}`}
        />
        <rect
          x="161.867"
          y="304.455"
          width="14"
          height="14"
          rx="1.5"
          transform="rotate(-45 161.867 304.455)"
          stroke="white"
          className={`background-normal ${isAnimationDefi ? 'background-active' : ''}`}
        />
        <rect
          x="381.867"
          y="403.455"
          width="14"
          height="14"
          rx="1.5"
          transform="rotate(-45 381.867 403.455)"
          stroke="white"
          className={`background-normal ${isAnimationDefi ? 'background-active' : ''}`}
        />
        <path
          d="M399.16 61.3496H441.66C457.124 61.3496 469.66 73.8856 469.66 89.3496V115.85C469.66 131.314 482.196 143.85 497.66 143.85H535.16"
          stroke="url(#paint4_linear_10009_3958)"
          stroke-width="0.9152"
        />
        <path
          d="M172.16 143.85H221.16C236.624 143.85 249.16 156.386 249.16 171.85V341.85"
          stroke="url(#paint5_linear_10009_3958)"
          stroke-width="0.9152"
        />
        <path
          d="M0.785156 304.85H221.161C236.625 304.85 249.161 317.386 249.161 332.85V526.85"
          stroke="url(#paint6_linear_10009_3958)"
          stroke-width="0.9152"
        />
        <path
          d="M242.16 224.342H199.766C184.302 224.342 171.766 236.878 171.766 252.342V295.85"
          stroke="url(#paint7_linear_10009_3958)"
          stroke-width="0.9152"
        />
        <path
          d="M249.168 333.857V375.455C249.168 390.919 261.704 403.455 277.168 403.455H569.002"
          stroke="url(#paint8_linear_10009_3958)"
          stroke-width="0.9152"
        />
        <path
          d="M391.758 394.346V230.229"
          stroke="url(#paint9_linear_10009_3958)"
          className={`line-normal ${isAnimationDefi ? 'line-active' : ''}`}
        />
        <path
          d="M391.754 231.834V274.869C391.754 290.333 404.29 302.869 419.754 302.869H446.703C462.167 302.869 474.703 315.405 474.703 330.869V396.766"
          stroke="url(#paint10_linear_10009_3958)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10009_3958"
          x1="284.824"
          y1="308.303"
          x2="284.824"
          y2="522.891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.5" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10009_3958"
          x1="361.857"
          y1="144.169"
          x2="361.857"
          y2="69.9377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10009_3958"
          x1="0.783203"
          y1="144.35"
          x2="531.158"
          y2="144.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.35" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10009_3958"
          x1="172.16"
          y1="136.85"
          x2="172.16"
          y2="19.8496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_10009_3958"
          x1="393.16"
          y1="61.8714"
          x2="543.16"
          y2="61.8714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_10009_3958"
          x1="76.6661"
          y1="143.925"
          x2="250.17"
          y2="335.435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_10009_3958"
          x1="18.1993"
          y1="305.916"
          x2="292.699"
          y2="416.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.576428" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_10009_3958"
          x1="166.244"
          y1="224.544"
          x2="256.772"
          y2="263.537"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.576428" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_10009_3958"
          x1="238.495"
          y1="334.415"
          x2="536.695"
          y2="477.066"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.576428" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_10009_3958"
          x1="392.258"
          y1="230.229"
          x2="392.258"
          y2="394.346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_10009_3958"
          x1="433.229"
          y1="231.834"
          x2="433.229"
          y2="396.766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.5" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ImageBgLaunch

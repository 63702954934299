import { useContext, useState } from 'react'
import { WrapContainer } from '../../Layout/styled'
import { AutoImage } from '../ImageCommon'
import {
  ListStepRoadMap,
  StepsRoad,
  TitleRoadMap,
  WrapLine,
  WrapRoadMap,
  WrapStepsRoad,
} from './styled'
import { ContextProviderWrapper } from '../Context'
import Spline from '@splinetool/react-spline'

const STEPS = [
  {
    time: 'PHASE 1',
    title: 'Kibble DeFAI Public Sale & Listing',
    value: 0,
    steps: [
      {
        step: 'AVAX Meta Aggregator',
      },
      {
        step: 'Launchpad Mainet',
      },
      {
        step: 'Kibble DeFAI',
      },
      {
        step: 'Launchpad Multichain',
      },
      {
        step: '$KIB Listing',
      },
    ],
  },
  {
    time: 'PHASE 2',
    title: 'Cross-Chain Expansion',
    value: 1,
    steps: [
      {
        step: 'Kibble Cross-Chain',
      },
      { step: 'Kibble DeFAI Trading' },
      { step: 'AI-Driven Liquidity Management' },
      { step: 'Integrate 10 DEX Aggregator' },
      { step: 'Expand 10 Blockchain' },
    ],
  },
  {
    time: 'PHASE 3',
    title: 'AI-Powered Trading',
    value: 2,
    steps: [
      {
        step: 'AI Market Insights',
      },
      {
        step: 'Kibble DeFAI App IOS & Android',
      },
      {
        step: 'Margin Trading',
      },
      {
        step: 'Lending & Borrowing',
      },
      {
        step: 'Expand AI Model',
      },
    ],
  },
  {
    time: 'PHASE 4',
    title: 'Smarter & Safer Trading',
    value: 3,
    steps: [
      {
        step: 'Enhanced Security',
      },
      {
        step: 'Prediction Game',
      },
      {
        step: 'Integration New DEXs',
      },
      {
        step: 'Interface Improvements',
      },
      {
        step: 'Upgrade AI Trading Assistant',
      },
    ],
  },
]

export default function RoadMap() {
  const [stepActive, setStepActive] = useState(0)
  const { isMobile } = useContext(ContextProviderWrapper)!
  return (
    <WrapRoadMap
      style={{
        background: '#000',
      }}
    >
      <div className="img-light-red">
        <img src="/assets/images/img-light-red.png" alt="img" />
      </div>
      {!isMobile && (
        <div className="img-road-map">
          <Spline scene="https://prod.spline.design/HDAw-r382bwkZU90/scene.splinecode" />
        </div>
      )}
      <WrapContainer>
        <TitleRoadMap data-aos="fade-up" className="row-center">
          <h2 className="row-center">
            <div className="dot"></div>
            GOAL OF KIBBLE
          </h2>
          <h1>Kibble Roadmap</h1>
          <p>
            Kibble seeks to redefine decentralized finance by simplifying DeFi for everyone, support
            from Avalanche C-Chain to Multi-chain
          </p>
        </TitleRoadMap>
        <ListStepRoadMap className="row-right">
          {STEPS.map((item: any, index: number) => (
            <WrapStepsRoad data-aos="fade-up" key={index} className="row-right">
              <h2 className={stepActive === item.value ? 'active' : ''}>{item.time}</h2>
              <WrapLine className="row-center">
                {stepActive === item.value && !isMobile && <div className="blur-red"></div>}
                <AutoImage
                  className={stepActive === item.value ? 'dot-red' : 'dot-black'}
                  src={stepActive === item.value ? 'icn_dot_red.svg' : 'icn_dot_black.svg'}
                  width={20}
                />
                <div
                  style={{
                    height: index === 0 && !isMobile ? '280px' : isMobile ? '160px' : '268px',
                  }}
                  className={stepActive === item.value ? 'line-red' : 'line-black'}
                ></div>
              </WrapLine>
              <StepsRoad>
                <h1>{item.title}</h1>
                <ul>
                  {item.steps.map((step: any, jdex: number) => (
                    <li key={jdex}>{step.step}</li>
                  ))}
                </ul>
              </StepsRoad>{' '}
            </WrapStepsRoad>
          ))}
        </ListStepRoadMap>
      </WrapContainer>
    </WrapRoadMap>
  )
}

import React, { useRef, useState } from 'react'
import { ItemDefiai } from '../styled'
import LineDefi from '../line'
import { AutoImage } from '../../../components/ImageCommon'
import { useScramble } from 'use-scramble'
import { useScroll, useTransform } from 'framer-motion'

const ItemDefi = () => {
  const [isAnimationDefi, setIsAnimationDefi] = useState(false)
  const handleAddclassDefi = () => {
    setIsAnimationDefi(true)
    replay()
  }
  const handleRemoveclassDefi = () => {
    setIsAnimationDefi(false)
  }
  const { ref, replay } = useScramble({
    text: 'DeFAI',
    speed: 0.6,
    tick: 1,
    step: 1,
    scramble: 4,
    seed: 0,
  })
  return (
    <ItemDefiai
      onMouseEnter={() => handleAddclassDefi()}
      onMouseLeave={() => handleRemoveclassDefi()}
      className="item-card"
    >
      <div className="block-image">
        <LineDefi isAnimationDefi={isAnimationDefi} />
        <div
          className={`icon-circle-01 icon-circle ${isAnimationDefi ? 'icon-circel-active' : ''}`}
        ></div>
        <div
          className={`icon-circle-02 icon-circle ${isAnimationDefi ? 'icon-circel-active' : ''}`}
        ></div>
        <div
          className={`icon-circle-03 icon-circle ${isAnimationDefi ? 'icon-circel-active' : ''}`}
        ></div>
        <div
          className={`icon-circle-04 icon-circle ${isAnimationDefi ? 'icon-circel-active' : ''}`}
        ></div>
        <div
          className={`icon-circle-transparent-01 icon-circle-transparent ${
            isAnimationDefi ? 'icon-circel-transparent-active' : ''
          }`}
        ></div>
        <div
          className={`icon-circle-transparent-02 icon-circle-transparent ${
            isAnimationDefi ? 'icon-circel-transparent-active' : ''
          }`}
        ></div>
        <div
          className={`icon-circle-transparent-03 icon-circle-transparent ${
            isAnimationDefi ? 'icon-circel-transparent-active' : ''
          }`}
        ></div>
        <div
          className={`icon-circle-transparent-04 icon-circle-transparent ${
            isAnimationDefi ? 'icon-circel-transparent-active' : ''
          }`}
        ></div>
        <div
          className={`icon-dot-middle icon-dot-01 ${isAnimationDefi ? 'icon-dot-active' : ''}`}
        ></div>
        <div
          className={`icon-dot-middle icon-dot-02 ${isAnimationDefi ? 'icon-dot-active' : ''}`}
        ></div>
        <div
          className={`icon-dot-middle icon-dot-03 ${isAnimationDefi ? 'icon-dot-active' : ''}`}
        ></div>
        <div
          className={`icon-dot-middle icon-dot-04 ${isAnimationDefi ? 'icon-dot-active' : ''}`}
        ></div>
        <div className={`icon-brand icon-brand-01 ${isAnimationDefi ? 'icon-brand-active' : ''}`}>
          <AutoImage src="future_img_06.png" width={172} />
        </div>
        <div className={`icon-brand icon-brand-02 ${isAnimationDefi ? 'icon-brand-active' : ''}`}>
          <AutoImage src="future_img_07.png" width={172} />
        </div>
        <div className={`block-bg-decor ${isAnimationDefi ? 'block-bg-decor-active' : ''}`}></div>
      </div>
      <div className="item-card-content">
        <h3 className="tile-card">
          <span className="normal">Kibble</span>
          <span className="special" ref={ref}>
            {' '}
          </span>
        </h3>
        <p className="text-card">
          Utilizes AI to automate trading processes, including intelligent order routing and
          real-time on-chain data analysis, minimizing slippage and optimizing transaction fees.
        </p>
      </div>
    </ItemDefiai>
  )
}

export default ItemDefi

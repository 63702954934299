import axios from 'axios'
import { useState, useEffect } from 'react'

export const useMexcPrice = () => {
  const [price, setPrice] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const fetchPrice = async () => {
    setLoading(true)
    setError(null)

    try {
      const response: any = await axios.get(`https://aggregator.kibble.exchange/api/kib-price`, {
        headers: {
          Accept: '*',
          'Content-Type': 'application/json',
        },
      })
      const currentPrice = response.data.price
      setPrice(currentPrice)
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to fetch price'
      setError(errorMessage)
      console.error('Error fetching price:', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPrice()

    const intervalId = setInterval(() => {
      fetchPrice()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [])

  return { price, loading, error, refetch: fetchPrice }
}

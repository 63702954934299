import styled from 'styled-components'

export const WrapRoadMap = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  .img-road-map {
    position: absolute;
    width: 70%;
    height: 70%;
    left: -200px;
    top: 300px;
    border-radius: 50%;
    overflow: hidden;
    pointer-events: none;
  }
  .img-light-red {
    width: 1000px;
    height: 1000px;
    position: absolute;
    z-index: 2;
    left: -500px;
    top: 0;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
    .img-light-red {
      width: 100%;
      height: 100%;
      left: -200px;
    }
  }
`
export const TitleRoadMap = styled.div`
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  flex-direction: column;
  h2 {
    color: #ff3556;
    text-align: center;
    font-family: Tektur;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    .dot {
      width: 5.047px;
      height: 5.047px;
      border-radius: 1px;
      background: #ff3556;
      margin-right: 8px;
    }
  }
  h1 {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 64px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  p {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: 'Wix Madefor Text';
    font-size: 16px;
    text-transform: capitalize;
    line-height: 24px;
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
  }
`
export const ListStepRoadMap = styled.div`
  position: relative;
  flex-direction: column;
  max-width: 980px;
  margin: 0 auto;
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
`
export const WrapLine = styled.div`
  flex-direction: column;
  width: 100px;
  position: relative;
  .dot-red,
  .dot-black {
    position: relative;
    z-index: 2;
  }
  .line-red {
    flex-shrink: 0;
    width: 2px;
    background: linear-gradient(180deg, rgb(83 83 83) 0%, #ed3656 100%),
      lightgray -669.586px -348.679px / 108.215% 138.47% no-repeat;
    border-radius: 4px;
    margin-top: -1px;
    position: relative;
    z-index: 1;
  }
  .line-black {
    flex-shrink: 0;
    width: 2px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    margin-top: -5px;
    position: relative;
    z-index: 1;
  }
  .blur-red {
    border-radius: 50%;
    position: absolute;
    opacity: 0.4;
    background: radial-gradient(
      41.07% 41.07% at 31.92% 49.98%,
      #ff3546 0%,
      rgba(234, 65, 114, 0) 100%
    );
    width: 80px;
    height: 80px;
    top: -30px;
    left: 25px;
  }
  @media screen and (max-width: 767px) {
    width: 50px;
    flex-shrink: 0;
  }
`
export const StepsRoad = styled.div`
  width: 340px;
  position: relative;
  padding-bottom: 60px;
  h1 {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 16px;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
  }
  li {
    color: rgba(255, 255, 255, 0.75);
    font-family: 'Wix Madefor Text';
    font-size: 18px;
    text-transform: capitalize;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
    width: 250px;
    h1 {
      font-size: 18px;
    }
    li {
      font-size: 14px;
    }
  }
`
export const WrapStepsRoad = styled.div`
  position: relative;
  align-items: flex-start;
  h2 {
    color: rgba(255, 255, 255, 0.5);
    font-family: Tektur;
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    white-space: nowrap;
    &.active {
      color: #ff3556;
    }
  }
  &:last-child {
    .line-black {
      background: linear-gradient(180deg, rgb(83 83 83) 0%, #000 50%),
        lightgray -669.586px -348.679px / 108.215% 138.47% no-repeat;
    }
    .line-red {
      background: linear-gradient(180deg, rgb(237 54 86) 0%, #000 50%),
        lightgray -669.586px -348.679px / 108.215% 138.47% no-repeat;
    }
    ${StepsRoad} {
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
    h2 {
      font-size: 18px;
      width: 90px;
      flex-shrink: 0;
    }
  }
`

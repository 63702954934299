import { BlockContentSM, ButtonCommon, WrapContainer } from '../../Layout/styled'
import { AutoImage } from '../ImageCommon'
import { useScramble } from 'use-scramble'
import {
  ChartWhoWeAre,
  ItemData,
  ShowData,
  TitleWhoWeAre,
  WrapChart,
  WrapImgChart,
  WrapWhoWeAre,
} from './styled'
import { ChartSvg } from './chartSvg'
import { useContext, useEffect, useRef, useState } from 'react'
import { ContextProviderWrapper } from '../Context'
import { useInView } from 'framer-motion'
import axios from 'axios'
import { convertFixed } from '../../utils/convertNumber'

export default function WhoWeAre() {
  const containerWho: any = useRef(null)
  const isInView = useInView(containerWho)
  const { isMobile } = useContext(ContextProviderWrapper)!
  const [data, setData] = useState<any>({})
  const { ref } = useScramble({
    text: 'Who we are?',
    speed: 0.6,
    tick: 1,
    step: 1,
    scramble: 4,
    seed: 0,
  })

  const LIST_TOTAL = [
    {
      title: 'Total Txs',
      des: data ? data?.txs : 0,
    },
    {
      title: 'Total Users',
      des: data ? data?.users : 0,
    },
    {
      title: `Total Volume`,
      des: data ? convertFixed(data?.volume) : 0,
    },
    {
      title: 'Monthly Users',
      des: data ? data?.monthlyUsers : 0,
    },
  ]

  useEffect(() => {
    // Get references to the path and the circle
    const path = document.getElementById('motionPath')
    const circle = document.getElementById('movingCircle')
    const elmLineRed: any = document.getElementsByClassName('line-red')

    if (!isInView) {
      if (elmLineRed && elmLineRed.length > 0) {
        elmLineRed[0].style.animation = ''
      }
      if (circle) {
        circle.style.opacity = '0'
      }
      return
    } else {
      if (elmLineRed && elmLineRed.length > 0 && !isMobile) {
        elmLineRed[0].style.animation = 'lineUp 2s linear forwards'
      }
      if (circle) {
        circle.style.opacity = '1'
      }
    }
    // Get the total length of the path
    // @ts-ignore
    const pathLength = path.getTotalLength()

    let progress = 0 // Progress along the path (0 to 1)
    const duration = 3600 // Animation duration in milliseconds
    const startTime = performance.now() // Start time of the animation

    function animate() {
      // Calculate elapsed time
      const elapsedTime = performance.now() - startTime
      progress = 1 - (elapsedTime % duration) / duration // Normalize progress to [0, 1]

      // Get the point at the current progress along the path
      // @ts-ignore
      const point = path.getPointAtLength(progress * pathLength)
      if (point.x > 560) return

      // Update the circle's position
      // @ts-ignore
      circle.setAttribute('cx', point.x)
      // @ts-ignore
      circle.setAttribute('cy', point.y)

      // Request the next frame
      requestAnimationFrame(animate)
    }

    // Start the animation
    animate()
  }, [isInView])

  const handleGetData = async () => {
    try {
      const res = await axios.get('https://aggregator.kibble.exchange/api/statistics/detail')
      if (res) {
        setData(res?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetData()
  }, [])

  return (
    <WrapWhoWeAre ref={containerWho}>
      <WrapContainer className="is-reverst">
        <WrapImgChart data-aos="fade-up">
          <div className="img-line-chart">
            <ChartSvg />
          </div>
        </WrapImgChart>
        <BlockContentSM>
          <WrapChart className="row-center">
            <TitleWhoWeAre data-aos="fade-up">
              <h2 className="row-left">
                <div className="dot"></div>
                STONEMILES
              </h2>
              <h1 ref={ref}></h1>
              <p className="des">
                Kibble is a pioneering DeFi platform that integrates AI to optimize all your entire
                DeFi experience
              </p>
              <ButtonCommon to="/">
                <p>
                  <span>EXPLORE NOW</span>
                  <span>EXPLORE NOW</span>
                </p>
              </ButtonCommon>
            </TitleWhoWeAre>
            <ChartWhoWeAre data-aos="fade-up" className="row-right">
              <ShowData className="row-right">
                <div className="img-k-red">
                  <AutoImage
                    data-aos="zoom-in"
                    data-aos-delay="800"
                    style={{
                      zIndex: 1,
                      position: 'relative',
                    }}
                    src="img-k-red.png"
                    width={'100%'}
                  />
                  <div data-aos-delay="900" data-aos="fade-down" className="img-line-top">
                    <AutoImage src="img-line-t.png" width={'100%'} />
                  </div>
                  <div data-aos-delay="700" data-aos="fade-left" className="img-line-right">
                    <AutoImage src="img-line-r.png" width={'100%'} />
                  </div>
                  <div data-aos-delay="700" data-aos="fade-up" className="img-line-bottom">
                    <AutoImage src="img-line-b.png" width={'100%'} />
                  </div>
                  <div data-aos-delay="700" data-aos="fade-right" className="img-line-left">
                    <AutoImage src="img-line-l.png" width={'100%'} />
                  </div>
                </div>
                {LIST_TOTAL.map((item: any, index: number) => (
                  <ItemData data-aos="zoom-in" key={index}>
                    <h2>{item.title}</h2>
                    <h1>{item.des}</h1>
                  </ItemData>
                ))}
              </ShowData>
            </ChartWhoWeAre>
          </WrapChart>
        </BlockContentSM>
      </WrapContainer>
    </WrapWhoWeAre>
  )
}

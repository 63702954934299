import { useContext, useEffect, useRef } from 'react'
import { AutoImage } from '../../ImageCommon'
import { ContentChatUser } from '../Chat/styled'
import { BlockFuntion, ContentChatAdmin, GuildContainer, GuildWrapper, PostContent } from './styled'
import { ContextProviderWrapper } from '../../Context'

const Guild = ({ showContent }: any) => {
  console.log('showContent', showContent)
  const itemBottom = useRef<HTMLDivElement>(null)
  const { isMobile } = useContext(ContextProviderWrapper)!

  useEffect(() => {
    if (showContent > 0 && !isMobile) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          itemBottom.current?.scrollIntoView({ behavior: 'smooth' })
        })
      }, 1500)
    }
  }, [showContent])

  //scroll in mobile
  useEffect(() => {
    if (isMobile && showContent > 0) {
      const container = document.querySelector('.post-content') as HTMLDivElement | null
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth',
        })
      }
    }
  }, [showContent, isMobile])

  return (
    <GuildContainer>
      <GuildWrapper>
        <PostContent className="post-content">
          {showContent > 0 && (
            <ContentChatAdmin className="row-left">
              <div className="image-user">
                <img
                  src="/assets/images/icn_bot.png"
                  width={40}
                  height={40}
                  loading="lazy"
                  alt="Bot"
                />
              </div>
              <div className="wrap-content">
                <h3>Hello and welcome to Kibble!</h3>
                <p
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  I’m Kibble DeFAI, your AI assistant here to make decentralized finance simpler,
                  smarter, and more accessible.
                </p>
                <p>
                  With Kibble DeFAI, you’re stepping into the future of DeFi—powered by AI to
                  optimize your trading, liquidity management, and market insights.
                </p>
                <p>
                  Whether you want to trade with a quick command, find the best liquidity pools, get
                  real-time market predictions, or execute trades at the best prices across DEXs,
                  I’ve got you covered.
                </p>
                <p
                  style={{
                    marginTop: '10px',
                    fontWeight: '500',
                  }}
                >
                  Just chat with me to explore features 🚀
                </p>
                <BlockFuntion>
                  {listFunction.map((item, index) => (
                    <div key={index} className="item-function">
                      <img src={item.icon} width={16} height={16} loading="lazy" alt="Icon" />
                    </div>
                  ))}
                </BlockFuntion>
              </div>
            </ContentChatAdmin>
          )}
          {showContent > 1 && (
            <ContentChatUser>
              <p>Show me prompt example for swap transaction</p>
            </ContentChatUser>
          )}
          {showContent > 2 && (
            <ContentChatAdmin className="row-left">
              <div className="image-user">
                <img
                  src="/assets/images/icn_bot.png"
                  width={40}
                  height={40}
                  loading="lazy"
                  alt="Bot"
                />
              </div>
              <div className="wrap-content">
                <p>Want to make a swap? Just tell me what you’d like! Here are some examples:</p>
                <p
                  style={{
                    marginTop: '5px',
                  }}
                >
                  Swap Transaction Example:
                </p>
                <ul>
                  <li>
                    {' '}
                    <span>Normal swap:</span> 'Swap 100 USDC for ETH on Avalanche C-Chain.'{' '}
                  </li>
                  <li>
                    {' '}
                    <span>Cross-chain swap:</span> 'Swap 50 MATIC on Polygon to BNB on Avalanche
                    C-Chain.'{' '}
                  </li>
                  <li>
                    {' '}
                    <span>Custom tweak:</span> 'Swap 200 KIB for USDT on Avalanche C-Chain with max
                    slippage of 1%.'{' '}
                  </li>
                </ul>
                <p>
                  Let me know what you’re thinking, and I’ll handle the rest with the best rates and
                  routing!
                </p>
                <BlockFuntion>
                  {listFunction.map((item, index) => (
                    <div key={index} className="item-function">
                      <img src={item.icon} width={16} height={16} loading="lazy" alt="Icon" />
                    </div>
                  ))}
                </BlockFuntion>
              </div>
            </ContentChatAdmin>
          )}
          {showContent > 3 && (
            <ContentChatUser>
              <p>Check my portfolio</p>
            </ContentChatUser>
          )}
          {showContent > 4 && (
            <ContentChatAdmin className="row-left">
              <div className="image-user">
                <img
                  src="/assets/images/icn_bot.png"
                  width={40}
                  height={40}
                  loading="lazy"
                  alt="Bot"
                />
              </div>
              <div className="wrap-content">
                <p>You have currently own 30M KIB and 30 AVAX</p>
                <div className="img-swap">
                  <AutoImage src="img-swap-token.png" width={'100%'} />
                </div>
                <BlockFuntion>
                  {listFunction.map((item, index) => (
                    <div key={index} className="item-function">
                      <img src={item.icon} width={16} height={16} loading="eager" alt="Icon" />
                    </div>
                  ))}
                </BlockFuntion>
              </div>
            </ContentChatAdmin>
          )}
          {showContent > 5 && (
            <ContentChatUser>
              <p>Cool, lets swap 1M KIB to AVAX on Avalanche C-Chain with max slippage of 1%</p>
            </ContentChatUser>
          )}
          {showContent > 6 && (
            <ContentChatAdmin className="row-left">
              <div className="image-user">
                <img
                  src="/assets/images/icn_bot.png"
                  width={40}
                  height={40}
                  loading="lazy"
                  alt="Bot"
                />
              </div>
              <div className="wrap-content">
                <p>Are you sure you’d like to swap 1M KIB to AVAX</p>
                <div className="img-swap-frame">
                  <AutoImage src="img-swap-frame.png" width={'100%'} loading="eager" />
                </div>
                <div className="row-left">
                  <div className="btn-confirm row-center">
                    <AutoImage src="icn_swap.svg" width={'16px'} />
                    <span>Confirm Transaction</span>
                  </div>
                  <div className="btn-cancle row-center">
                    <span>Cancle</span>
                  </div>
                </div>
              </div>
            </ContentChatAdmin>
          )}
          {!isMobile && <div ref={itemBottom} />}
          {isMobile && <div ref={itemBottom} />}
        </PostContent>
      </GuildWrapper>
    </GuildContainer>
  )
}

const listFunction = [
  {
    icon: '/assets/images/icn_function_01.svg',
  },
  {
    icon: '/assets/images/icn_function_02.svg',
  },
  {
    icon: '/assets/images/icn_function_03.svg',
  },
  {
    icon: '/assets/images/icn_function_04.svg',
  },
  {
    icon: '/assets/images/icn_function_05.svg',
  },
]

export default Guild

import React, { useRef, useState } from 'react'
import { ItemLaunch } from '../styled'
import { AutoImage } from '../../../components/ImageCommon'
import ImageBgLaunch from './imageBgLaunch'
import { useScramble } from 'use-scramble'
import { useScroll, useTransform } from 'framer-motion'

const ItemLaunchpad = () => {
  const [isAnimationDefi, setIsAnimationDefi] = useState(false)
  const handleAddclassDefi = () => {
    setIsAnimationDefi(true)
    replay()
  }
  const handleRemoveclassDefi = () => {
    setIsAnimationDefi(false)
  }
  const { ref, replay } = useScramble({
    text: 'Launchpad Multichain',
    speed: 0.6,
    tick: 1,
    step: 1,
    scramble: 4,
    seed: 0,
  })
  return (
    <ItemLaunch
      className="item-card"
      onMouseEnter={() => handleAddclassDefi()}
      onMouseLeave={() => handleRemoveclassDefi()}
    >
      <div className="block-image">
        <ImageBgLaunch isAnimationDefi={isAnimationDefi} />
        <div
          className={`icon-square icon-square-01 ${isAnimationDefi ? 'icon-square-active' : ''}`}
        ></div>
        <div
          className={`icon-circle icon-circle-01 ${isAnimationDefi ? 'icon-circle-active' : ''}`}
        ></div>
        <div
          className={`icon-circle icon-circle-02 ${isAnimationDefi ? 'icon-circle-active' : ''}`}
        ></div>
        <div
          className={`icon-circle-big icon-circle-big-01 ${
            isAnimationDefi ? 'icon-circle-big-active' : ''
          }`}
        ></div>
        <div
          className={`icon-circle-big icon-circle-big-02 ${
            isAnimationDefi ? 'icon-circle-big-active' : ''
          }`}
        ></div>
        <div
          className={`icon-circle-big icon-circle-big-03 ${
            isAnimationDefi ? 'icon-circle-big-active' : ''
          }`}
        ></div>
        <div
          className={`icon-circle-big icon-circle-big-04 ${
            isAnimationDefi ? 'icon-circle-big-active' : ''
          }`}
        ></div>
        <div className={`block-icon-plan ${isAnimationDefi ? 'icon-plan-active' : ''}`}>
          <AutoImage className={`icon-plan`} src="future_img_10.png" width={228} />
        </div>
      </div>
      <div className="item-card-content">
        <h3 className="tile-card">
          <span className="normal">Kibble</span>
          <span className="special" ref={ref}>
            {' '}
          </span>
        </h3>
        <p className="text-card">
          Supports project launches and Initial DEX Offerings (IDOs) across various blockchains,
          connecting developers with a diverse investor community and fostering the growth of a
          multichain DeFi ecosystem.
        </p>
      </div>
    </ItemLaunch>
  )
}

export default ItemLaunchpad

import styled from 'styled-components'

export const GuildContainer = styled.div`
  position: relative;
  z-index: 1;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background: #414141;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media screen and (max-width: 768px) {
    height: 55vh;
    overflow: auto;
    min-height: unset;
  }
`
export const GuildWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  overflow: scroll;
  .image-user {
    flex-shrink: 0;
    position: relative;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    height: 100%;
    .image-user {
      width: 32px;
      height: 32px;
      top: -4px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`
export const PostContent = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  h3 {
    color: #e84142;
    font-family: 'Wix Madefor Text';
    font-size: 15px;
    line-height: 135%;
    margin-bottom: 5px;
  }
  p {
    color: #fff;
    font-size: 15px;
    line-height: 135%;
    font-family: 'Wix Madefor Text';
  }
  .address {
    color: #e84142;
    padding: 0 4px;
    line-height: 1;
    font-family: 'Wix Madefor Text';
  }
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    h3,
    p {
      font-size: 12px;
    }
  }
`
export const BlockFuntion = styled.div`
  border-radius: 500px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
  width: max-content;
  margin-top: 5px;
  .item-function {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.26s ease;
    &:hover {
      opacity: 0.7;
    }
  }
`

export const ContentChatAdmin = styled.div`
  width: 100%;
  text-align: left;
  align-items: flex-start;
  gap: 5px;
  animation: chatUp 1s ease;
  .btn-confirm {
    padding: 8px 16px;
    gap: 4px;
    border-radius: 500px;
    background: #000;
    margin-right: 4px;
    width: fit-content;
    span {
      color: #fff;
      font-family: 'Wix Madefor Text';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
    }
  }
  .btn-cancle {
    padding: 8px 16px;
    gap: 4px;
    border-radius: 500px;
    background: #525252;
    width: fit-content;
    span {
      color: #fff;
      font-family: 'Wix Madefor Text';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
    }
  }
  .wrap-content {
    border-radius: 4px 16px 16px 16px;
    width: 100%;
    ul {
      list-style: disc;
      margin: 5px 0;
      li {
        color: #fff;
        font-size: 15px;
        line-height: 135%;
        font-family: 'Wix Madefor Text';
        margin-left: 20px;
        font-weight: 400;
        span {
          font-weight: bold;
        }
      }
    }
  }
  p {
    text-align: left;
    animation: typing 2s steps(30, end);
    overflow: hidden;
    width: 100%;
  }

  .img-swap {
    max-width: 230px;
    margin: 10px 0;
    img {
      width: 100%;
    }
  }

  .img-swap-frame {
    max-width: 375px;
    margin: 10px 0;
    img {
      width: 100%;
    }
  }
  @keyframes typing {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes chatUp {
    from {
      transform: translateY(10px);
    }
    to {
      transform: translateY(0px);
    }
  }
  @media screen and (max-width: 767px) {
    .wrap-content {
      word-break: break-word;
      ul {
        li {
          font-size: 12px;
        }
      }
    }
    .btn-confirm {
      span {
        font-size: 14px;
      }
    }
    .btn-cancle {
      span {
        font-size: 14px;
      }
    }
  }
`

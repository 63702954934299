import { Link } from 'react-router-dom'
import {
  FooterContact,
  FooterContainer,
  FooterLast,
  FooterLastItem,
  FooterNavigation,
  FooterTitle,
  FooterWrapper,
  ListSocial,
} from './styled'
import { ButtonCommon } from '../styled'
import { AutoImage } from '../../components/ImageCommon'

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <div className="wrapper-inner">
          <FooterNavigation data-aos="fade-up" data-aos-duration="1000">
            {listNavigation.map((items, index) => (
              <li className="item-navigation" key={index}>
                <FooterTitle>{items.title}</FooterTitle>
                <ul className="sub-list">
                  {items.listText.map((item, index) => (
                    <li key={index}>
                      <Link className="trans" to={item.href} target="_blank">
                        {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </FooterNavigation>
          <FooterContact data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
            <FooterTitle>newsletter</FooterTitle>
            <p className="text-contact">You read this far, might as well sign up.</p>
            <div className="content-contact">
              <a className="mail trans" href="mailto: admin@kibble.exchange">
                admin@kibble.exchange
              </a>
              <ButtonCommon to="/">
                <p>
                  <span>SUBSCRIBE</span>
                  <span>SUBSCRIBE</span>
                </p>
              </ButtonCommon>
            </div>
          </FooterContact>
        </div>
      </FooterWrapper>
      <ListSocial>
        {listSocial.map((item: any, index) => (
          <li key={index}>
            <a href={item.href} className="trans" target="_blank" rel="noreferrer">
              <AutoImage src={item.icon} />
              <span>{item.text}</span>
            </a>
          </li>
        ))}
      </ListSocial>
      <FooterLast>
        {listText.map((item: any, index: number) => (
          <FooterLastItem
            whileHover={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
            key={index}
          >
            <AutoImage src={item.text} />
          </FooterLastItem>
        ))}
      </FooterLast>
    </FooterContainer>
  )
}
const listNavigation = [
  {
    title: 'About Kibble',
    listText: [
      {
        text: 'About Us',
        href: '#',
      },
      {
        text: 'Partners',
        href: '#',
      },
      {
        text: 'Privacy Policy',
        href: '#',
      },
      {
        text: 'Terms of service',
        href: '#',
      },
      {
        text: 'Whitepaper',
        href: 'https://docs.kibble.exchange/',
      },
    ],
  },
  {
    title: 'PRODUCTS',
    listText: [
      {
        text: 'PitchDeck',
        href: 'https://docsend.com/view/uau8pwnjb49v462u',
      },
      {
        text: 'Fees',
        href: '#',
      },
      {
        text: 'Launchpad',
        href: 'https://app.kibble.exchange/launchpad/avax',
      },
      {
        text: 'Staking',
        href: 'https://app.kibble.exchange/staking/avax',
      },
    ],
  },
  {
    title: 'SUPPORT',
    listText: [
      {
        text: 'Help Center',
        href: '#',
      },
      {
        text: 'Contact Us',
        href: '#',
      },
      {
        text: 'Terms',
        href: '#',
      },
    ],
  },
]
const listText = [
  {
    text: 'footer_text_01.svg',
  },
  {
    text: 'footer_text_02.svg',
  },
  {
    text: 'footer_text_03.svg',
  },
  {
    text: 'footer_text_04.svg',
  },
  {
    text: 'footer_text_04.svg',
  },
  {
    text: 'footer_text_05.svg',
  },
  {
    text: 'footer_text_06.svg',
  },
]
const listSocial = [
  {
    href: 'https://t.me/KibbleOfficial',
    icon: 'icon_telegram.svg',
    text: 'Telegram',
  },
  { href: 'https://x.com/kibbleexchange', icon: 'icon_twitter.svg', text: 'Twitter' },
  { href: 'https://kibble.medium.com/', icon: 'icon_medium.svg', text: 'Medium' },
  { href: 'https://discord.com/invite/kibbleai', icon: 'icon_discord_02.svg', text: 'Discord' },
  { href: 'https://www.youtube.com/@KibbleExchange', icon: 'icon_youtube.svg', text: 'Youtube' },
]
export default Footer

import { ButtonCommon } from '../../Layout/styled'
import { BlockTitlePrimaryContainer, Text, TitlePrimaryContainer } from './styled'

interface BlockPrimaryProps {
  titleSmall: string
  titleBig: any
  text?: string
  button?: string
  buttonLink?: string
}
export const BlockTitlePrimary: React.FC<BlockPrimaryProps> = function TitlePrimary({
  titleSmall,
  titleBig,
  text,
  button,
  buttonLink,
}) {
  return (
    <BlockTitlePrimaryContainer>
      <TitlePrimaryContainer data-aos="fade-up">
        <span className="title-small">{titleSmall}</span>
        <span className="title-big">{titleBig}</span>
      </TitlePrimaryContainer>
      {text && <Text data-aos="fade-up">{text}</Text>}
      {button && (
        <ButtonCommon to={buttonLink || '/'}>
          <p>
            <span>{button}</span>
            <span>{button}</span>
          </p>
        </ButtonCommon>
      )}
    </BlockTitlePrimaryContainer>
  )
}

import styled from 'styled-components'
import { motion } from 'framer-motion'

export const FutureContainer = styled.section`
  position: relative;
  padding-bottom: 200px;
  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
`
export const FutureWrapper = styled.div`
  max-width: 1160px;
  padding: 0 20px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  .wrapper-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .content-left {
    max-width: 355px;
    position: sticky;
    top: 30%;
    padding-top: 100px;
  }
  .content-right {
    position: relative;
    z-index: 2;
    max-width: 569px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    .wrapper-inner {
      display: block;
    }
    .content-left {
      position: static;
      margin-bottom: 40px;
    }
    .content-right {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 768px) {
    .wrapper-inner {
      display: block;
    }
    .content-left {
      max-width: unset;
      margin-bottom: 40px;
      padding-top: 0;
      top: 0;
    }
    .content-right {
      max-width: 390px;
      margin: 0 auto;
      padding-top: 0;
    }
  }
`
export const ListCardContent = styled.div`
  padding-top: 100px;
  .item-card {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    background: linear-gradient(180deg, #282828 0%, #0c0c0c 100%);
    padding-bottom: 44px;
    position: sticky;
    top: 100px;
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
  .item-card.pinned {
    transition: top 0.3s ease;
  }
  .item-card {
    &.card-first {
      z-index: 1;
      &:hover {
        .icon-dot {
          width: 45%;
        }
        .block-logo-first {
          transform: translate(-50%, -50%) rotate(120deg);
          &:before {
            opacity: 1;
          }
        }
        .icon-line {
          opacity: 1;
        }
      }
    }
  }
  .item-card-content {
    padding: 0 32px;
    position: relative;
    z-index: 1;
  }
  .tile-card {
    margin-bottom: 16px;
  }
  .normal {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 114.286% */
    text-transform: capitalize;
    margin-right: 5px;
  }
  .special {
    background: linear-gradient(90deg, #ff3556 19.11%, #ff63a1 30.57%, #ffd779 39.11%, #fff 62.94%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Tektur;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-transform: capitalize;
  }
  .text-card {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Wix Madefor Text';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    text-transform: capitalize;
  }
  .block-image {
    position: relative;
    height: 500px;
    .background {
      position: absolute;
    }
  }
  .icon-dot {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 40%;
    transition: all 0.26s ease;
    pointer-events: none;
  }
  .block-logo-first {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.26s ease;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 601px;
      background: radial-gradient(
        26.25% 26.25% at 49.96% 49.98%,
        #ea4172 0%,
        rgba(234, 65, 114, 0) 100%
      );
      filter: blur(116.69403839111328px);
      width: 601px;
      height: 590px;
      opacity: 0;
      transition: all 1s ease;
      pointer-events: none;
    }
  }
  .icon-logo-2 {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translateX(-50%);
  }
  .icon-line {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.26s ease;
  }
  @media (max-height: 800px) {
    .item-card {
      top: 50px !important;
    }
    .block-image {
      height: 420px;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 0;
    .item-card {
      top: 0 !important;
      transform: unset !important;
      padding-bottom: 0;
      &:last-child {
        margin-bottom: 0 !important;
      }
      &:before {
        width: 100%;
        height: 424px;
      }
    }
    .item-card {
      &.card-first {
        .block-logo-first {
          img {
            width: 100%;
          }
        }
        .background-first {
          width: 100%;
        }
        .icon-dot {
          width: 70%;
        }
        .icon-logo-2 {
          width: 15%;
          top: 35.2%;
        }
        &:hover {
          .icon-dot {
            width: 60%;
          }
        }
      }
    }
    .special {
      font-size: 26px;
    }
    .text-card {
      font-size: 16px;
    }
    .item-card-content {
      padding: 0 15px;
      position: relative;
      z-index: 1;
    }
    .icon-line {
      width: 46%;
    }
  }
`
export const ItemDefiai = styled(motion.div)`
  border-radius: 16px;
  /* box-shadow: 0px 4px 24px 0px rgba(255, 255, 255, 0.1) inset; */
  z-index: 2;
  .block-image {
    svg {
      width: 100%;
    }
  }
  .icon-circle {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    background: #656565;
    transition: all 1s ease;
    &.icon-circel-active {
      transform: rotate(-360deg);
    }
  }
  .icon-circle-01 {
    top: 83px;
    left: 76px;
    &.icon-circel-active {
      left: 230px;
      transform: rotate(360deg);
    }
  }
  .icon-circle-02 {
    top: 82px;
    right: 77px;
    &.icon-circel-active {
      right: 196px;
    }
  }
  .icon-circle-03 {
    bottom: 109px;
    left: 76px;
    &.icon-circel-active {
      left: 230px;
      transform: rotate(360deg);
    }
  }
  .icon-circle-04 {
    bottom: 109px;
    right: 77px;
    &.icon-circel-active {
      right: 200px;
    }
  }
  .icon-circle-transparent {
    border-radius: 2px;
    border: 1px solid #656565;
    width: 15px;
    height: 15px;
    transform: rotate(-45deg);
    position: absolute;
    transition: all 0.5s ease;
    &.icon-circel-transparent-active {
      transform: rotate(90deg);
    }
    &.icon-circle-transparent-01 {
      right: 155px;
      top: 156px;
    }
    &.icon-circle-transparent-02 {
      left: 155px;
      top: 156px;
    }
    &.icon-circle-transparent-03 {
      left: 155px;
      bottom: 176px;
    }
    &.icon-circle-transparent-04 {
      right: 155px;
      bottom: 177px;
    }
  }
  .icon-dot-middle {
    width: 10px;
    height: 9.316px;
    background-color: #656565;
    border-radius: 50%;
    position: absolute;
    transition: all 1s ease;
    transition-delay: 0.2s;
    &.icon-dot-01 {
      top: 133px;
      left: 47%;
      &.icon-dot-active {
        top: 0;
      }
    }
    &.icon-dot-02 {
      top: 115px;
      left: 51.1%;
      &.icon-dot-active {
        top: 0;
      }
    }
    &.icon-dot-03 {
      bottom: 110px;
      left: 47.1%;
      &.icon-dot-active {
        bottom: 50px;
      }
    }
    &.icon-dot-04 {
      bottom: 131px;
      left: 51.2%;
      &.icon-dot-active {
        bottom: 50px;
      }
    }
  }
  .icon-brand {
    position: absolute;
    transition: all 0.26s ease;
    &.icon-brand-01 {
      top: 24%;
      left: 157px;
      &.icon-brand-active {
        left: 161px;
      }
    }
    &.icon-brand-02 {
      top: 24%;
      right: 154px;
      &.icon-brand-active {
        right: 161px;
      }
    }
  }
  .block-bg-decor {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 601px;
    background: radial-gradient(
      26.25% 26.25% at 49.96% 49.98%,
      #ea4172 0%,
      rgba(234, 65, 114, 0) 100%
    );
    filter: blur(116.69403839111328px);
    width: 601px;
    height: 590px;
    opacity: 0;
    transition: all 1s ease;
    pointer-events: none;
    &.block-bg-decor-active {
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px) {
    .block-image {
      height: 300px;
      svg {
        height: 452px;
        height: 100%;
      }
    }
    .icon-circle-01 {
      top: 40px;
      left: 50px;
      &.icon-circel-active {
        left: 140px;
      }
    }
    .icon-circle-02 {
      top: 40px;
      right: 50px;
      &.icon-circel-active {
        right: 136px;
      }
    }
    .icon-circle-03 {
      bottom: 54px;
      left: 51px;
      &.icon-circel-active {
        left: 104px;
      }
    }
    .icon-circle-04 {
      bottom: 54px;
      right: 50px;
      &.icon-circel-active {
        right: 110px;
      }
    }
    .icon-circle-transparent {
      &.icon-circle-transparent-01 {
        right: 104px;
        top: 83px;
      }
      &.icon-circle-transparent-02 {
        left: 104px;
        top: 83px;
      }
      &.icon-circle-transparent-03 {
        left: 111px;
        bottom: 96px;
      }
      &.icon-circle-transparent-04 {
        right: 112px;
        bottom: 97px;
      }
    }
    .icon-dot-middle {
      &.icon-dot-01 {
        top: 64px;
        left: 46.7%;
      }
      &.icon-dot-02 {
        top: 59px;
        left: 50.7%;
      }
      &.icon-dot-03 {
        bottom: 74px;
        left: 46.6%;
        &.icon-dot-active {
          bottom: 140px;
        }
      }
      &.icon-dot-04 {
        bottom: 87px;
        left: 50.7%;
        &.icon-dot-active {
          bottom: 140px;
        }
      }
    }
    .icon-brand {
      &.icon-brand-01 {
        width: 117px;
        top: 17%;
        left: 25%;
        &.icon-brand-active {
          left: 26%;
        }
      }
      &.icon-brand-02 {
        width: 117px;
        top: 17%;
        right: 25%;
        &.icon-brand-active {
          right: 26%;
        }
      }
      img {
        width: 100%;
      }
    }
  }
`
export const ItemCrossChain = styled(motion.div)`
  z-index: 3;
  .icon-decor-circle {
    position: absolute;
    transition: all 0.5s ease;
    &.icon-decor-circle-01 {
      top: -41px;
      left: -43px;
      &.icon-decor-circle-active {
        left: -185px;
      }
    }
    &.icon-decor-circle-02 {
      top: 70px;
      left: -39px;
      &.icon-decor-circle-active {
        left: 45px;
      }
    }
    &.icon-decor-circle-03 {
      top: 191px;
      left: -54px;
      &.icon-decor-circle-active {
        left: -193px;
      }
    }
    &.icon-decor-circle-04 {
      top: 311px;
      left: -44px;
      &.icon-decor-circle-active {
        left: 44px;
      }
    }
  }
  .logo-kibble {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .block-logo-kibble {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    transition: transform 0.5s ease;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 601px;
      background: radial-gradient(
        26.25% 26.25% at 49.96% 49.98%,
        #ea4172 0%,
        rgba(234, 65, 114, 0) 100%
      );
      filter: blur(116.69403839111328px);
      width: 601px;
      height: 590px;
      opacity: 0;
      transition: all 1s ease;
      pointer-events: none;
    }
    &.logo-bg-kibble-active {
      transform: translate(-50%, -50%) rotate(90deg);
      &:before {
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .block-image {
      .background-cross {
        width: 100%;
      }
    }
    .icon-decor-circle {
      width: 100%;
      &.icon-decor-circle-01 {
        top: -16px;
      }
      &.icon-decor-circle-02 {
        top: 60px;
        left: -42px;
      }
      &.icon-decor-circle-03 {
        top: 151px;
        left: -42px;
      }
      &.icon-decor-circle-04 {
        top: 241px;
        left: -42px;
      }
    }
    .logo-kibble {
      width: 15%;
      top: 39.4%;
    }
    .block-logo-kibble {
      text-align: center;
      img {
        width: 90%;
      }
    }
  }
`
export const ItemLaunch = styled(motion.div)`
  z-index: 4;
  .icon-square {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    background: #656565;
    transition: all 1s ease;
    &.icon-square-01 {
      top: 20px;
      left: 164px;
      &.icon-square-active {
        top: 136px;
        border-radius: 50%;
      }
    }
  }
  .icon-circle {
    position: absolute;
    width: 15px;
    height: 15px;
    background: #656565;
    border-radius: 50%;
    transition: all 1s ease;
    &.icon-circle-01 {
      top: 224px;
      left: 384px;
      &.icon-circle-active {
        top: 264px;
      }
    }
    &.icon-circle-02 {
      top: 304px;
      left: 313px;
      &.icon-circle-active {
        top: 395px;
      }
    }
  }
  .icon-circle-big {
    width: 65px;
    height: 65px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(153, 153, 153, 0.15) 100%
    );
    box-shadow: 0px 3.765px 23.247px 0px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(5px);
    position: absolute;
    border-radius: 33px;
    overflow: hidden;
    transition: all 1s ease;
    &.icon-circle-big-01 {
      top: 285px;
      left: 20px;
      &.icon-circle-big-active {
        top: 105px;
      }
    }
    &.icon-circle-big-02 {
      top: 335px;
      left: 120px;
      &.icon-circle-big-active {
        top: 45px;
      }
    }
    &.icon-circle-big-03 {
      top: 292px;
      right: 108px;
      &.icon-circle-big-active {
        top: 70px;
      }
    }
    &.icon-circle-big-04 {
      top: 332px;
      right: 15px;
      &.icon-circle-big-active {
        top: 28px;
      }
    }
  }
  .block-icon-plan {
    position: absolute;
    top: 46.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s ease;
    width: 228px;
    height: 342px;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 601px;
      background: radial-gradient(
        26.25% 26.25% at 49.96% 49.98%,
        #ea4172 0%,
        rgba(234, 65, 114, 0) 100%
      );
      filter: blur(116.69403839111328px);
      width: 601px;
      height: 590px;
      opacity: 0;
      transition: all 1s ease;
      pointer-events: none;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
    &.icon-plan-active {
      top: 24.5%;
      &:before {
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .block-image {
      svg {
        width: 100%;
        height: 25rem;
      }
    }
    .icon-square {
      &.icon-square-01 {
        top: 91px;
        left: 110px;
      }
    }
    .icon-circle {
      &.icon-circle-01 {
        top: 237px;
        left: 261px;
      }
      &.icon-circle-02 {
        top: 298px;
        left: 212px;
      }
    }
    .block-icon-plan {
      width: 40%;
      top: 56.5%;
      img {
        width: 100%;
      }
    }
  }
  .icon-circle-big {
    width: 40px;
    height: 40px;
    &.icon-circle-big-03 {
      right: 73px;
    }
  }
`

import React, { useRef } from 'react'
import { AutoImage } from '../../../components/ImageCommon'
import { useScramble } from 'use-scramble'
import { motion, useScroll, useTransform } from 'framer-motion'

const ItemFirst = () => {
  const { ref, replay } = useScramble({
    text: 'Meta Aggrerrator',
    speed: 0.6,
    tick: 1,
    step: 1,
    scramble: 4,
    seed: 0,
  })
  return (
    <motion.div className="item-card card-first" onMouseEnter={replay}>
      <div className="block-image">
        <AutoImage className="background-first background" src={'future_img_01.png'} width={569} />
        <AutoImage className="icon-dot" src={'future_img_02.png'} width={382} />
        <div className="block-logo-first">
          <AutoImage className="icon-logo" src={'future_img_03.png'} width={234} />
        </div>
        <AutoImage className="icon-logo-2" src={'future_img_04.svg'} width={75} />
        <AutoImage className="icon-line" src={'future_img_05.svg'} width={176} />
      </div>
      <div className="item-card-content">
        <h3 className="tile-card">
          <span className="normal">Kibble</span>
          <span className="special" ref={ref}>
            {' '}
          </span>
        </h3>
        <p className="text-card">
          Aggregates liquidity from leading decentralized exchanges (DEXs) across multiple
          blockchains, ensuring users access the best prices, deep liquidity, and swift transactions
          within a single interface.
        </p>
      </div>
    </motion.div>
  )
}

export default ItemFirst

import { useRef, useState } from 'react'
import { AutoImage } from '../../../components/ImageCommon'
import { ItemCrossChain } from '../styled'
import { useScramble } from 'use-scramble'
import { useScroll, useTransform } from 'framer-motion'

const ItemCross = () => {
  const [isAnimationDefi, setIsAnimationDefi] = useState(false)
  const handleAddclassDefi = () => {
    setIsAnimationDefi(true)
    replay()
  }
  const handleRemoveclassDefi = () => {
    setIsAnimationDefi(false)
  }
  const { ref, replay } = useScramble({
    text: 'Cross-Chain',
    speed: 0.6,
    tick: 1,
    step: 1,
    scramble: 4,
    seed: 0,
  })
  return (
    <ItemCrossChain
      className="item-card"
      onMouseEnter={() => handleAddclassDefi()}
      onMouseLeave={() => handleRemoveclassDefi()}
    >
      <div className="block-image">
        <AutoImage className="background-cross" src={'future_img_08.png'} width={569} />
        <AutoImage
          className={`icon-decor-circle icon-decor-circle-01 ${
            isAnimationDefi ? 'icon-decor-circle-active' : ''
          }`}
          src="future_decor_01.png"
          width={678}
        />
        <AutoImage
          className={`icon-decor-circle icon-decor-circle-02 ${
            isAnimationDefi ? 'icon-decor-circle-active' : ''
          }`}
          src="future_decor_01.png"
          width={678}
        />
        <AutoImage
          className={`icon-decor-circle icon-decor-circle-03 ${
            isAnimationDefi ? 'icon-decor-circle-active' : ''
          }`}
          src="future_decor_01.png"
          width={678}
        />
        <AutoImage
          className={`icon-decor-circle icon-decor-circle-04 ${
            isAnimationDefi ? 'icon-decor-circle-active' : ''
          }`}
          src="future_decor_01.png"
          width={678}
        />
        <AutoImage className={`logo-kibble`} src="future_img_04.svg" width={75} />
        <div className={`block-logo-kibble ${isAnimationDefi ? 'logo-bg-kibble-active' : ''}`}>
          <AutoImage className={`logo-bg-kibble`} src="future_img_09.svg" width={233} />
        </div>
      </div>
      <div className="item-card-content">
        <h3 className="tile-card">
          <span className="normal">Kibble</span>
          <span className="special" ref={ref}>
            {' '}
          </span>
        </h3>
        <p className="text-card">
          Facilitates seamless asset swaps between blockchains such as AVAX, Ethereum, and BSC,
          enabling users to conduct cross-chain transactions with low fees and high security.
        </p>
      </div>
    </ItemCrossChain>
  )
}

export default ItemCross

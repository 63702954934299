import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ButtonCommon = styled(Link)`
  position: relative;
  border-radius: 4px;
  background: #fff;
  color: #000;
  padding: 15px 20px;
  width: 140px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Wix Madefor Text', sans-serif;
  overflow: hidden;
  box-sizing: initial;
  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  &:before {
    top: 5px;
    left: 5px;
  }
  &:after {
    bottom: 5px;
    right: 5px;
    background: url('/assets/images/icn_arrow.svg') center / contain no-repeat;
  }
  &:before {
    background: url('/assets/images/icn_arrow_02.svg') center / contain no-repeat;
  }
  p {
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    top: 50%;
    height: 48px;
    transform: translateY(-50%);
    span {
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &:nth-child(1) {
        transform: translateY(15px);
      }
      &:nth-child(2) {
        transform: translateY(-48px);
      }
    }
  }
  &:hover {
    &:before {
      transform: translateY(31px) rotate(-80deg);
    }
    &:after {
      transform: translateY(-32px) rotate(-80deg);
    }
    p {
      span {
        &:nth-child(1) {
          transform: translateY(48px);
        }
        &:nth-child(2) {
          transform: translateY(-3px);
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 11px 10px;
    width: 104px;
    &:hover {
      &:before {
        transform: translateY(24px) rotate(-80deg);
      }
      &:after {
        transform: translateY(-22px) rotate(-80deg);
      }
    }
  }
`
export const BlockContent = styled.div`
  max-width: 1388px;
  margin: 0 auto;
  position: relative;
`
export const BlockContentSM = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 1200px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0;
  }
`
export const WrapContainer = styled.div`
  max-width: 100%;
  padding: 0 26px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`

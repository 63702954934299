import { Skeleton } from 'antd'
import { IconActive, SkeletonFormItem } from './styled'

const FormItem = ({ item, skeleton }: any) => {
  return (
    <div
      style={{
        animationDelay: item.delay,
      }}
    >
      {skeleton ? (
        <SkeletonFormItem>
          <IconActive delay={item.delay} />
          <Skeleton active paragraph={{ rows: 1, width: '100%' }} title={false} />
        </SkeletonFormItem>
      ) : (
        <>
          <figure>
            <img src={item.icon} alt="icon" />
          </figure>
          <p>{item.text}</p>
        </>
      )}
    </div>
  )
}

export default FormItem

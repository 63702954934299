import DefAi from '../components/DeFAi'
import JoinOurCommunity from '../components/JoinCom'
import RoadMap from '../components/RoadMap'
import WhoWeAre from '../components/WhoWeAre'
import Future from './Future'
import Keyvisual from './Keyvisual'
import Work from './Work'
import WorkDefAi from './WorkDefAi'

const LandingPage = () => {
  return (
    <>
      <Keyvisual />
      <Future />
      <Work />
      <DefAi />
      <WorkDefAi />
      <WhoWeAre />
      <RoadMap />
      <JoinOurCommunity />
    </>
  )
}

export default LandingPage

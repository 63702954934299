import { useState } from 'react'
import { AutoImage } from '../../components/ImageCommon'
import { BlockTitlePrimary } from '../../components/TitlePrimary'
import LineDefi from './line'
import {
  FutureContainer,
  FutureWrapper,
  ItemCrossChain,
  ItemDefiai,
  ListCardContent,
} from './styled'
import ItemCross from './ItemCross'
import ItemDefi from './ItemDefi'
import ItemLaunchpad from './ItemLaunchpad'
import ItemFirst from './ItemFirst'
import MotionParallaxItem from './MotionParallaxItem'

const Future = () => {
  return (
    <FutureContainer>
      <FutureWrapper>
        <div className="wrapper-inner">
          <div className="content-left" data-aos="fade-up">
            <BlockTitlePrimary
              titleSmall="FUTURE OF KIBBLE"
              titleBig="Key Features"
              text="We’re here to fuel your trading edge on Avalanche C-Chain with top-tier tools. Enjoy the smarter experience and stronger DeFi."
              button="EXPLORE NOW"
            />
          </div>
          <div className="content-right">
            <ListCardContent>
              {listContent.map((item, index) => (
                <MotionParallaxItem key={index} index={index}>
                  <div className="item-card-inner">{item.component}</div>
                </MotionParallaxItem>
              ))}
            </ListCardContent>
          </div>
        </div>
      </FutureWrapper>
    </FutureContainer>
  )
}

const listContent = [
  {
    component: <ItemFirst />,
  },
  {
    component: <ItemDefi />,
  },
  {
    component: <ItemCross />,
  },
  {
    component: <ItemLaunchpad />,
  },
]

export default Future

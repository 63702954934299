import styled from 'styled-components'

export const BlockDiagramContainer = styled.div<{ delay?: any }>`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #ff3556;
  background: linear-gradient(180deg, rgba(255, 53, 86, 0.15) 0%, rgba(255, 174, 200, 0.15) 100%);
  box-shadow: 0px 3.765px 23.247px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(5px);
  opacity: 0;
  &.opacity {
    animation: opacityBlock 0.5s linear forwards;
    animation-delay: ${({ delay }) => delay};
  }
  p {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .dot {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    border: 1px solid #ff3556;
    background: #fff;
    &.right {
      top: 50%;
      right: -4px;
      margin-top: -4px;
    }
    &.left {
      left: -4px;
    }
    &.top {
      top: -4px;
    }
  }
  @keyframes opacityBlock {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

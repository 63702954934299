import styled from 'styled-components'
import { motion } from 'framer-motion'

export const FooterContainer = styled.footer`
  position: relative;
`
export const FooterWrapper = styled.div`
  max-width: 1160px;
  padding: 0 20px;
  margin: 0 auto;
  padding-top: 57px;
  margin-bottom: 76px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 1122px;
    height: 2px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white 47.14%,
      rgba(255, 255, 255, 0)
    );
    opacity: 0.5;
    border-radius: 1px;
  }
  .wrapper-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    &:before {
      width: 100%;
    }
    .wrapper-inner {
      display: block;
    }
  }
`
export const FooterTitle = styled.h2`
  color: rgba(255, 255, 255, 0.5);
  font-family: Tektur;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`
export const FooterNavigation = styled.ul`
  display: flex;
  align-items: flex-start;
  gap: 48px;
  .sub-list {
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    a {
      color: #fff;
      font-family: 'Wix Madefor Text';
      font-size: 20px;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 35px;
    .sub-list {
      a {
        font-size: 13px;
      }
    }
  }
`
export const FooterContact = styled.ul`
  max-width: 515px;
  width: 100%;
  .text-contact {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 40px;
  }
  .content-contact {
    display: flex;
    align-items: flex-end;
    gap: 11px;
  }
  .mail {
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Wix Madefor Text';
    font-size: 16px;
    font-weight: 500;
    padding: 14px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    max-width: unset;
    .text-contact {
      font-size: 18px;
    }
  }
`
export const FooterLast = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  border-radius: 28px;
  gap: 2.4rem;
  @media screen and (max-width: 768px) {
    padding: 15px 0;
    gap: 1.6rem;
  }
`
export const FooterLastItem = styled(motion.span)`
  &:nth-child(1) {
    width: 25.278vw;
  }
  &:nth-child(2) {
    width: 10.1389vw;
  }
  &:nth-child(3) {
    width: 2.934vw;
  }
  &:nth-child(4) {
    width: 10.07vw;
  }
  &:nth-child(5) {
    width: 10.07vw;
  }
  &:nth-child(6) {
    width: 7.57vw;
  }
  &:nth-child(7) {
    width: 11.806vw;
    margin-right: 0;
  }

  img {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: auto !important;
    height: 12vw;
    img {
      width: auto;
      height: 100%;
    }
  }
`
export const ListSocial = styled.ul`
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 1;
  li {
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(153, 153, 153, 0.15) 100%
    );
    box-shadow: 0px 3.765px 23.247px 0px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(17.5px);
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 14px 63px;
    }
    span {
      color: #fff;
      font-family: 'Wix Madefor Text';
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    position: static;
    justify-content: flex-start;
    padding: 0 10px;
    margin-bottom: 15px;
    li {
      width: calc(100% / 3 - 6px);
      a {
        padding: 10px 26px;
        img {
          width: 15px;
          height: 15px;
          object-fit: cover;
        }
      }
      span {
        font-size: 12px;
      }
    }
  }
`

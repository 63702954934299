import { BlockDiagramContainer } from './styled'

const BlockDiagram = ({ text, icon, isDot, isDotPosition, className, delay }: any) => {
  return (
    <BlockDiagramContainer delay={delay} className={className}>
      {isDot && <span className={isDotPosition ? `${isDotPosition} dot` : 'dot'} />}
      <figure>
        <img src={icon} alt="icon" />
      </figure>
      <p>{text}</p>
    </BlockDiagramContainer>
  )
}

export default BlockDiagram

import { useContext, useEffect, useRef, useState } from 'react'
import TextScrambleCommon from '../../components/TextScamble'
import { BlockTitlePrimary } from '../../components/TitlePrimary'
import BlockDiagram from '../Work/Diagram/Block'
import PathDiagram from '../Work/Diagram/Path'
import { DiagramChange, DiagramHead, WorkContainer, WorkWrapper } from '../Work/styled'
import {
  BrainWrapper,
  DiagramIntent,
  DiagramIntentWrapper,
  DiagramOutput,
  IntentForm,
  IntentFormInner,
  IntentFormSolver,
  IntentFormWrapper,
  IntentInnerItem,
  IntentItemSolver,
  OutputInner,
  WorkDefAiContainer,
} from './styled'
import { useInView } from 'framer-motion'
import DiagramForms from '../Work/Diagram/Form'
import { ContextProviderWrapper } from '../../components/Context'

const WorkDefAi = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!
  const containerDefi: any = useRef(null)
  const isInView = useInView(containerDefi, { once: true })
  const [infinite, setInfinite] = useState(false)

  const diagramAggregators = [
    {
      id: 1,
      delay: '2s',
    },
    {
      id: 2,
      delay: '2.2s',
    },
    {
      id: 3,
      delay: '2.4s',
    },
    {
      id: 4,
      delay: '2.6s',
    },
    {
      id: 5,
      delay: '2.8s',
    },
  ]

  const diagramIntent = [
    {
      id: 1,
      text: 'Wallet',
      icon: '/assets/images/icon_intent_wallet.svg',
      delay: '2.5s',
    },
    {
      id: 2,
      text: 'Balances',
      icon: '/assets/images/icon_intent_balance.svg',
      delay: '2.6s',
    },
    {
      id: 3,
      text: 'Transfer',
      icon: '/assets/images/icon_intent_transfer.svg',
      delay: '2.7s',
    },
    {
      id: 4,
      text: 'Market data (TVLs, prices, APYS)',
      icon: '/assets/images/icon_intent_market.svg',
      delay: '2.8s',
    },
  ]

  const diagramSolver: any = [
    {
      id: 1,
      name: 'Swap solvers',
      icons: [
        { icon: '/assets/images/icon_uni.png', delay: '2.5s' },
        { icon: '/assets/images/icon_sushi.png', delay: '2.6s' },
        { icon: '/assets/images/icon_1inch.png', delay: '2.7s' },
        { icon: '/assets/images/icon_matcha.png', delay: '2.8s' },
        { icon: '/assets/images/icon_kyber.png', delay: '2.9s' },
        { icon: '/assets/images/icon_para.png', delay: '3s' },
      ],
    },
    {
      id: 2,
      name: 'Bridge solvers',
      icons: [
        { icon: '/assets/images/icon_bridge_1.png', delay: '2.5s' },
        { icon: '/assets/images/icon_bridge_2.png', delay: '2.6s' },
        { icon: '/assets/images/icon_bridge_3.png', delay: '2.7s' },
        { icon: '/assets/images/icon_bridge_4.png', delay: '2.8s' },
      ],
    },
    {
      id: 3,
      name: 'DeFi solvers',
      icons: [
        { icon: '/assets/images/icon_defi_1.png', delay: '2.5s' },
        { icon: '/assets/images/icon_defi_2.png', delay: '2.6s' },
      ],
    },
  ]

  useEffect(() => {
    if (!infinite && isInView) {
      setTimeout(() => {
        setInfinite(true)
      }, 3500)
    }
  }, [isInView])

  return (
    <WorkDefAiContainer>
      <WorkContainer>
        <WorkWrapper>
          <BlockTitlePrimary
            titleSmall="How it work"
            titleBig={
              <p>
                How <TextScrambleCommon text="Kibble DeFAI" linear={true} />
                Work?
              </p>
            }
            text={`Kibble DeFAI’s architecture fuses AI precision with Web3 innovation, \n crafting a flawless synergy for next-level DeFi performance`}
          />
        </WorkWrapper>
        {!isMobile ? (
          <>
            <DiagramHead>
              <PathDiagram
                path={
                  <div className="start-line">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="187"
                      height="2"
                      viewBox="0 0 187 2"
                      fill="none"
                    >
                      <path
                        d="M1 1L185.5 1.00002"
                        stroke="url(#paint0_linear_7937_6192)"
                        stroke-width="2"
                        stroke-linecap="round"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_7937_6192"
                          x1="1"
                          y1="0.5"
                          x2="185.5"
                          y2="0.500016"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF3556" stop-opacity="0" />
                          <stop offset="1" stop-color="#FF3556" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="51"
                      height="51"
                      viewBox="0 0 51 51"
                      fill="none"
                      className={isInView ? 'opacity spread-point' : 'spread-point'}
                    >
                      <rect
                        width="51"
                        height="51"
                        rx="4.56664"
                        fill="#FF3556"
                        fill-opacity="0.05"
                        className="item spread-point_1"
                      />
                      <rect
                        x="5"
                        y="5"
                        width="41"
                        height="41"
                        rx="4.56664"
                        fill="#FF3556"
                        fill-opacity="0.1"
                        className="item spread-point_2"
                      />
                      <rect
                        x="10"
                        y="10"
                        width="31"
                        height="31"
                        rx="4.56664"
                        fill="#FF3556"
                        fill-opacity="0.15"
                        className="item spread-point_3"
                      />
                      <rect
                        x="15"
                        y="15"
                        width="21"
                        height="21"
                        rx="4.56664"
                        fill="#FF3556"
                        fill-opacity="0.2"
                        className="item spread-point_4"
                      />
                      <rect
                        x="20"
                        y="20"
                        width="10.9532"
                        height="10.9532"
                        rx="2.17034"
                        fill="#FF3556"
                        className="item spread-point_5"
                      />
                    </svg>
                  </div>
                }
                text="Start"
                classNameText={isInView ? 'opacity' : ''}
                delayText="0.2s"
                delayInfinite="0.2s"
              />
              <BlockDiagram
                text="Users"
                icon="/assets/images/icon_block_user.svg"
                delay="0.5s"
                className={isInView ? 'opacity' : ''}
              />
              <PathDiagram
                path={
                  <svg
                    width="139"
                    height="8"
                    viewBox="0 0 139 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="custom-line"
                  >
                    <path
                      d="M1 4L135 3.99999"
                      stroke="url(#paint0_linear_3_202)"
                      stroke-width="2"
                      stroke-linecap="round"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <rect
                      x="131.5"
                      y="0.5"
                      width="7"
                      height="7"
                      rx="1.5"
                      fill="white"
                      stroke="#F6AF6A"
                      className={isInView ? 'opacity' : ''}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3_202"
                        x1="1"
                        y1="3.5"
                        x2="135"
                        y2="3.49999"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="#FFC46B" />
                      </linearGradient>
                    </defs>
                  </svg>
                }
                text="Prompt"
                classNameText={isInView ? 'opacity' : ''}
                delayText="1.2s"
                delaySVG="1.2s"
                delayFill="1.2s"
                delayInfinite="0.6s"
              />
              <BrainWrapper>
                <PathDiagram
                  path={
                    <svg
                      width="78"
                      height="141"
                      viewBox="0 0 78 141"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="13.5"
                        y="130.5"
                        width="7"
                        height="7"
                        rx="1.5"
                        fill="white"
                        stroke="#FF3556"
                        className={isInView ? 'opacity' : ''}
                      />
                      <rect
                        x="57.5"
                        y="130.5"
                        width="7"
                        height="7"
                        rx="1.5"
                        fill="white"
                        stroke="#FF3556"
                        className={isInView ? 'opacity' : ''}
                      />
                      <path
                        d="M17 5.72205e-06V131"
                        stroke="url(#paint0_linear_3_201)"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M31 24V139"
                        stroke="url(#paint1_linear_3_201)"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M39 35V141"
                        stroke="url(#paint2_linear_3_201)"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M47 24V139"
                        stroke="url(#paint3_linear_3_201)"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M62 5.72205e-06V131"
                        stroke="url(#paint4_linear_3_201)"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M68 139V99.339C68 97.1298 69.7909 95.339 72 95.339H73C75.2091 95.339 77 93.5481 77 91.339V27"
                        stroke="url(#paint5_linear_3_201)"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M10 139V99.339C10 97.1298 8.20914 95.339 6 95.339H5C2.79086 95.339 1 93.5481 1 91.339V27"
                        stroke="url(#paint6_linear_3_201)"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_3_201"
                          x1="17.5"
                          y1="131"
                          x2="17.5"
                          y2="0"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF3A5E" />
                          <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_3_201"
                          x1="31.5"
                          y1="139"
                          x2="31.5"
                          y2="24"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF3A5E" />
                          <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_3_201"
                          x1="39.5"
                          y1="141"
                          x2="39.5"
                          y2="35"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF3A5E" />
                          <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear_3_201"
                          x1="47.5"
                          y1="139"
                          x2="47.5"
                          y2="24"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF3A5E" />
                          <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                        <linearGradient
                          id="paint4_linear_3_201"
                          x1="62.5"
                          y1="131"
                          x2="62.5"
                          y2="0"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF3A5E" />
                          <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                        <linearGradient
                          id="paint5_linear_3_201"
                          x1="72.5"
                          y1="27"
                          x2="72.5"
                          y2="139"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" stop-opacity="0" />
                          <stop offset="1" stop-color="#FF3A5E" />
                        </linearGradient>
                        <linearGradient
                          id="paint6_linear_3_201"
                          x1="5.5"
                          y1="27"
                          x2="5.5"
                          y2="139"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" stop-opacity="0" />
                          <stop offset="1" stop-color="#FF3A5E" />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                  delaySVG="1.6s"
                  delayText="1.6s"
                  delayFill="1.6s"
                  delayInfinite="1s"
                />
                <figure className={isInView ? 'opacity' : ''}>
                  <img src="/assets/images/icon_brain.png" alt="icon" />
                </figure>
              </BrainWrapper>
            </DiagramHead>
            <DiagramChange ref={containerDefi} className="custom">
              <PathDiagram
                path={
                  <svg
                    width="442"
                    height="85"
                    viewBox="0 0 442 85"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M199 0L199 81"
                      stroke="url(#paint0_linear_3_2012)"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M192 -8.21773e-06L192 21C192 29.8366 184.837 37 176 37L20 37C11.1634 37 4 44.1634 4 53L4 81"
                      stroke="url(#paint1_linear_3_2012)"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M250 -8.21773e-06L250 21C250 29.8366 257.163 37 266 37L422 37C430.837 37 438 44.1634 438 53L438 81"
                      stroke="url(#paint2_linear_3_2012)"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M213 0L213 81"
                      stroke="url(#paint3_linear_3_2012)"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M229 0L229 81"
                      stroke="url(#paint4_linear_3_2012)"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M221 0L221 81"
                      stroke="url(#paint5_linear_3_2012)"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M244 0L244 81"
                      stroke="url(#paint6_linear_3_2012)"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M0.5 79C0.5 78.1716 1.17157 77.5 2 77.5H6C6.82843 77.5 7.5 78.1716 7.5 79V83C7.5 83.8284 6.82843 84.5 6 84.5H2C1.17157 84.5 0.5 83.8284 0.5 83V79Z"
                      fill="white"
                      stroke="#FF3556"
                      className={isInView ? 'fill' : ''}
                    />
                    <path
                      d="M195.5 79C195.5 78.1716 196.172 77.5 197 77.5H201C201.828 77.5 202.5 78.1716 202.5 79V83C202.5 83.8284 201.828 84.5 201 84.5H197C196.172 84.5 195.5 83.8284 195.5 83V79Z"
                      fill="white"
                      stroke="#FF3556"
                      className={isInView ? 'fill' : ''}
                    />
                    <path
                      d="M240.5 79C240.5 78.1716 241.172 77.5 242 77.5H246C246.828 77.5 247.5 78.1716 247.5 79V83C247.5 83.8284 246.828 84.5 246 84.5H242C241.172 84.5 240.5 83.8284 240.5 83V79Z"
                      fill="white"
                      stroke="#FF3556"
                      className={isInView ? 'fill' : ''}
                    />
                    <path
                      d="M434.5 79C434.5 78.1716 435.172 77.5 436 77.5H440C440.828 77.5 441.5 78.1716 441.5 79V83C441.5 83.8284 440.828 84.5 440 84.5H436C435.172 84.5 434.5 83.8284 434.5 83V79Z"
                      fill="white"
                      stroke="#FF3556"
                      className={isInView ? 'fill' : ''}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3_2012"
                        x1="199.5"
                        y1="-2.18557e-08"
                        x2="199.5"
                        y2="81"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_3_2012"
                        x1="192.504"
                        y1="-8.76092e-08"
                        x2="192.504"
                        y2="37"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_3_2012"
                        x1="249.496"
                        y1="-8.76092e-08"
                        x2="249.496"
                        y2="37"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_3_2012"
                        x1="213.5"
                        y1="-2.18557e-08"
                        x2="213.5"
                        y2="81"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                      <linearGradient
                        id="paint4_linear_3_2012"
                        x1="229.5"
                        y1="-2.18557e-08"
                        x2="229.5"
                        y2="81"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                      <linearGradient
                        id="paint5_linear_3_2012"
                        x1="221.5"
                        y1="-2.18557e-08"
                        x2="221.5"
                        y2="81"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                      <linearGradient
                        id="paint6_linear_3_2012"
                        x1="244.5"
                        y1="-2.18557e-08"
                        x2="244.5"
                        y2="81"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                    </defs>
                  </svg>
                }
                classNameText={isInView ? 'opacity' : ''}
                delaySVG="1.8s"
                delayFill="1.8s"
                delayInfinite="1.5s"
              />
            </DiagramChange>
            <DiagramForms
              data={diagramAggregators}
              title="Extracted parameters"
              className={isInView ? 'appear loading' : 'loading'}
              delay="2s"
              skeleton={true}
            />
            <DiagramIntentWrapper>
              <DiagramIntent className={isInView ? 'appear' : ''}>
                <PathDiagram
                  path={
                    <svg
                      width="57"
                      height="240"
                      viewBox="0 0 57 240"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M49.3633 2.8125C49.3633 1.98407 50.0349 1.3125 50.8633 1.3125H54.8633C55.6917 1.3125 56.3633 1.98407 56.3633 2.8125V6.8125C56.3633 7.64093 55.6917 8.3125 54.8633 8.3125H50.8633C50.0349 8.3125 49.3633 7.64093 49.3633 6.8125V2.8125Z"
                        fill="white"
                        stroke="#FF3556"
                        className={isInView ? 'fill' : ''}
                      />
                      <path
                        d="M47.6406 4.8125H16.8633C8.02672 4.8125 0.863281 11.9759 0.863281 20.8125V220.813C0.863281 229.649 8.02673 236.812 16.8633 236.812H47.6406"
                        stroke="url(#paint0_linear_3_2022)"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M47.2852 233.93L47.2852 239.664L52.2818 236.805L47.2852 233.93Z"
                        fill="white"
                        className={isInView ? 'fill' : ''}
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_3_2022"
                          x1="90.2906"
                          y1="-67.6875"
                          x2="12.5676"
                          y2="233.8"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" stop-opacity="0" />
                          <stop offset="1" stop-color="white" />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                  classNameText={isInView ? 'opacity' : ''}
                  delaySVG="2.1s"
                  delayFill="2.1s"
                  delayInfinite="2.3s"
                />
                <IntentForm className={isInView ? 'appear' : ''}>
                  <h2>Intent Execution Engine</h2>
                  <IntentFormWrapper className={isInView ? 'appear' : ''}>
                    {diagramSolver.map((item: any, index: any) => {
                      return (
                        <IntentFormSolver key={index}>
                          <h3>{item.name}</h3>
                          <ul>
                            {item.icons.map((ico: any, index: any) => {
                              return (
                                <IntentItemSolver key={index} delay={ico.delay}>
                                  <img src={ico.icon} alt="icon" />
                                </IntentItemSolver>
                              )
                            })}
                          </ul>
                        </IntentFormSolver>
                      )
                    })}
                  </IntentFormWrapper>
                  <IntentFormInner className={isInView ? 'appear' : ''}>
                    <h3>Kibble solver</h3>
                    <ul>
                      {diagramIntent.map((item, index) => {
                        return (
                          <IntentInnerItem key={index} delay={item.delay}>
                            <figure>
                              <img src={item.icon} alt="icon" />
                            </figure>
                            <p>{item.text}</p>
                          </IntentInnerItem>
                        )
                      })}
                    </ul>
                  </IntentFormInner>
                </IntentForm>
              </DiagramIntent>
              <DiagramOutput>
                <PathDiagram
                  path={
                    <svg
                      width="135"
                      height="207"
                      viewBox="0 0 135 207"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 104L131 104"
                        stroke="url(#paint0_linear_3_20123)"
                        stroke-linecap="round"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M1 95H93C101.837 95 109 87.8366 109 79V21C109 12.1634 116.163 5 125 5L131 5"
                        stroke="url(#paint1_linear_3_20123)"
                        stroke-linecap="round"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M1 113H94.5C103.337 113 110.5 120.163 110.5 129V187C110.5 195.837 117.663 203 126.5 203H131"
                        stroke="url(#paint2_linear_3_20123)"
                        stroke-linecap="round"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M127.5 2C127.5 1.17157 128.172 0.5 129 0.5H133C133.828 0.5 134.5 1.17157 134.5 2V6C134.5 6.82843 133.828 7.5 133 7.5H129C128.172 7.5 127.5 6.82843 127.5 6V2Z"
                        fill="white"
                        stroke="#FF3556"
                        className={isInView ? 'fill' : ''}
                      />
                      <path
                        d="M127.5 102C127.5 101.172 128.172 100.5 129 100.5H133C133.828 100.5 134.5 101.172 134.5 102V106C134.5 106.828 133.828 107.5 133 107.5H129C128.172 107.5 127.5 106.828 127.5 106V102Z"
                        fill="white"
                        stroke="#FF3556"
                        className={isInView ? 'fill' : ''}
                      />
                      <path
                        d="M127.5 201C127.5 200.172 128.172 199.5 129 199.5H133C133.828 199.5 134.5 200.172 134.5 201V205C134.5 205.828 133.828 206.5 133 206.5H129C128.172 206.5 127.5 205.828 127.5 205V201Z"
                        fill="white"
                        stroke="#FF3556"
                        className={isInView ? 'fill' : ''}
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_3_20123"
                          x1="1"
                          y1="103.5"
                          x2="131"
                          y2="103.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" stop-opacity="0" />
                          <stop offset="1" stop-color="#FF3556" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_3_20123"
                          x1="-15"
                          y1="95.5625"
                          x2="141.342"
                          y2="20.9472"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" stop-opacity="0" />
                          <stop offset="1" stop-color="#FF3556" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_3_20123"
                          x1="-15"
                          y1="112.437"
                          x2="141.342"
                          y2="187.053"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" stop-opacity="0" />
                          <stop offset="1" stop-color="#FF3556" />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                  text="Output"
                  classNameText={isInView ? 'opacity' : ''}
                  delayText="2.5s"
                  delaySVG="2.5s"
                  delayFill="2.5s"
                  delayInfinite="3.2s"
                />
                <OutputInner>
                  <BlockDiagram
                    text="Transaction to sign"
                    icon="/assets/images/icon_block_sign.svg"
                    delay="2.7s"
                    className={isInView ? 'opacity' : ''}
                  />
                  <BlockDiagram
                    text="Text answer"
                    icon="/assets/images/icon_block_answer.svg"
                    delay="2.7s"
                    className={isInView ? 'opacity' : ''}
                  />
                  <BlockDiagram
                    text="Market data"
                    icon="/assets/images/icon_intent_market.svg"
                    delay="2.7s"
                    className={isInView ? 'opacity' : ''}
                  />
                </OutputInner>
              </DiagramOutput>
            </DiagramIntentWrapper>
          </>
        ) : (
          <figure>
            <img src="/assets/images/flow_work_defi.png" alt="icon" />
          </figure>
        )}
      </WorkContainer>
    </WorkDefAiContainer>
  )
}

export default WorkDefAi

import styled from 'styled-components'

export const WrapDefAi = styled.section`
  position: relative;
  margin-bottom: 150px;
  /* height: 300vh; */
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    margin-bottom: 70px;
  }
`
export const WrapBgDefAi = styled.div`
  position: relative;
  width: 100%;
  border-radius: 16px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 992px;
    background: url('/assets/images/work_bg.png') no-repeat center / cover;
    pointer-events: none;
  }
`
export const WrapContentDefAi = styled.div`
  position: relative;
  padding-top: 200px;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const TitleDefAi = styled.div`
  /* position: sticky;
  top: 50%;
  transform: translateY(-50%); */
  max-width: 355px;
  h2 {
    color: #fff;
    text-align: left;
    font-family: Tektur;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
    .dot {
      width: 5px;
      height: 5px;
      border-radius: 1px;
      background: #fff;
      margin-right: 8px;
    }
  }
  h1 {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 64px;
    font-weight: 500;
    line-height: 64.674px;
    text-transform: capitalize;
  }
  p.des {
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Wix Madefor Text';
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    margin-bottom: 40px;
    margin-top: 16px;
  }
  .img-box {
    max-width: 250px;
    position: absolute;
    z-index: 2;
    top: 50px;
    right: -110px;
  }
  @media screen and (max-width: 1360px) {
    max-width: unset;
    h1 {
      font-size: 35px;
    }
    .img-box {
      max-width: 200px;
      right: -10px;
      top: -140px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    .img-box {
      max-width: 120px;
      right: 0px;
      top: -40px;
    }
    p.des {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 14px;
    }
  }
`
export const FrameChatDefAi = styled.div`
  /* position: sticky;
  top: 0; */
  width: calc(100% - 355px);
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`

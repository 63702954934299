import TextScrambleCommon from '../../components/TextScamble'
import { BlockTitlePrimary } from '../../components/TitlePrimary'
import BlockDiagram from './Diagram/Block'
import PathDiagram from './Diagram/Path'
import {
  DiagramBottom,
  DiagramBottomForm,
  DiagramBottomWrap,
  DiagramChange,
  DiagramFinal,
  DiagramHead,
  WorkContainer,
  WorkFlowDiagram,
  WorkWrapper,
} from './styled'
import DiagramForms from './Diagram/Form'
import { useInView } from 'framer-motion'
import { useContext, useEffect, useRef, useState } from 'react'
import { ContextProviderWrapper } from '../../components/Context'

const Work = () => {
  const container: any = useRef(null)
  const isInView = useInView(container, { once: true, margin: '-200px 0px 0px 0px' })
  const { isMobile } = useContext(ContextProviderWrapper)!
  const [infinite, setInfinite] = useState(false)

  const diagramAggregators = [
    {
      id: 1,
      text: '1inch',
      icon: '/assets/images/icon_1inch.png',
      delay: '2.2s',
    },
    {
      id: 2,
      text: 'Matcha',
      icon: '/assets/images/icon_matcha.png',
      delay: '2.4s',
    },
    {
      id: 3,
      text: 'Kyber',
      icon: '/assets/images/icon_kyber.png',
      delay: '2.6s',
    },
    {
      id: 4,
      text: 'Slingshot',
      icon: '/assets/images/icon_sling.png',
      delay: '2.8s',
    },
    {
      id: 5,
      text: 'ParaSwap',
      icon: '/assets/images/icon_para.png',
      delay: '3s',
    },
  ]

  const diagramAmm = [
    {
      id: 1,
      text: 'UniSwap',
      icon: '/assets/images/icon_uni.png',
      delay: '2.4s',
    },
    {
      id: 2,
      text: 'Curve',
      icon: '/assets/images/icon_curve.png',
      delay: '2.6s',
    },
    {
      id: 3,
      text: 'SushiSwap',
      icon: '/assets/images/icon_sushi.png',
      delay: '2.8s',
    },
  ]

  const diagramOrderBook = [
    {
      id: 1,
      text: '0x',
      icon: '/assets/images/icon_0x.png',
      delay: '2.4s',
    },
    {
      id: 1,
      text: 'Loopring',
      icon: '/assets/images/icon_loop.png',
      delay: '2.6s',
    },
    {
      id: 1,
      text: 'Serum',
      icon: '/assets/images/icon_serum.png',
      delay: '2.8s',
    },
  ]

  useEffect(() => {
    if (!infinite && isInView) {
      setTimeout(() => {
        setInfinite(true)
      }, 3500)
    }
  }, [isInView])

  return (
    <WorkContainer>
      <WorkWrapper>
        <BlockTitlePrimary
          titleSmall="How it work"
          titleBig={
            <p>
              How <TextScrambleCommon text="Meta-Aggregator" linear={true} />
              <span>Work?</span>
            </p>
          }
        />
      </WorkWrapper>
      <WorkFlowDiagram>
        {!isMobile ? (
          <>
            <DiagramHead>
              <PathDiagram
                path={
                  <div className="start-line">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="187"
                      height="2"
                      viewBox="0 0 187 2"
                      fill="none"
                    >
                      <path
                        d="M1 1L185.5 1.00002"
                        stroke="url(#paint0_linear_7937_6192)"
                        stroke-width="2"
                        stroke-linecap="round"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_7937_6192"
                          x1="1"
                          y1="0.5"
                          x2="185.5"
                          y2="0.500016"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF3556" stop-opacity="0" />
                          <stop offset="1" stop-color="#FF3556" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="51"
                      height="51"
                      viewBox="0 0 51 51"
                      fill="none"
                      className={isInView ? 'opacity spread-point' : 'spread-point'}
                    >
                      <rect
                        width="51"
                        height="51"
                        rx="4.56664"
                        fill="#FF3556"
                        fill-opacity="0.05"
                        className="item spread-point_1"
                      />
                      <rect
                        x="5"
                        y="5"
                        width="41"
                        height="41"
                        rx="4.56664"
                        fill="#FF3556"
                        fill-opacity="0.1"
                        className="item spread-point_2"
                      />
                      <rect
                        x="10"
                        y="10"
                        width="31"
                        height="31"
                        rx="4.56664"
                        fill="#FF3556"
                        fill-opacity="0.15"
                        className="item spread-point_3"
                      />
                      <rect
                        x="15"
                        y="15"
                        width="21"
                        height="21"
                        rx="4.56664"
                        fill="#FF3556"
                        fill-opacity="0.2"
                        className="item spread-point_4"
                      />
                      <rect
                        x="20"
                        y="20"
                        width="10.9532"
                        height="10.9532"
                        rx="2.17034"
                        fill="#FF3556"
                        className="item spread-point_5"
                      />
                    </svg>
                  </div>
                }
                text="Start"
                classNameText={isInView ? 'opacity' : ''}
                delayText="0.2s"
                delayInfinite="0.2s"
              />
              <BlockDiagram
                text="Traders"
                icon="/assets/images/icon_block_1.svg"
                delay="0.5s"
                className={isInView ? 'opacity' : ''}
              />
              <PathDiagram
                path={
                  <svg
                    width="134"
                    height="7"
                    viewBox="0 0 134 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="custom-line"
                  >
                    <path
                      d="M1.5 3.5L130.191 3.49999"
                      stroke="url(#paint0_linear_3_20)"
                      stroke-width="2"
                      stroke-linecap="round"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M128.117 0.632812L128.117 6.36719L133.114 3.5081L128.117 0.632812Z"
                      fill="white"
                      className={isInView && !infinite ? 'fill' : infinite ? 'infinite-fill' : ''}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3_20"
                        x1="1.5"
                        y1="3"
                        x2="130.191"
                        y2="2.99999"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                    </defs>
                  </svg>
                }
                text="Orders"
                classNameText={isInView ? 'opacity' : ''}
                delayText="1s"
                delaySVG="1s"
                delayFill="1s"
                delayInfinite="0.6s"
              />
              <figure className={isInView ? 'opacity' : ''}>
                <img src="/assets/images/icon_head.png" alt="icon" />
              </figure>
            </DiagramHead>
            <DiagramChange className="custom">
              <PathDiagram
                path={
                  <svg
                    width="179"
                    height="88"
                    viewBox="0 0 179 88"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="custom-line"
                  >
                    <path
                      d="M178.12 1.07617V22.7807C178.12 33.8264 169.165 42.7807 158.12 42.7807H23C11.9543 42.7807 3 51.735 3 62.7807V87.2211"
                      stroke="url(#paint0_linear_3_2066)"
                      stroke-linecap="round"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M5.86719 82.2285H0.132812L2.9919 87.2252L5.86719 82.2285Z"
                      fill="white"
                      className={isInView ? 'fill' : ''}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3_2066"
                        x1="223.5"
                        y1="-113"
                        x2="3"
                        y2="87.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                    </defs>
                  </svg>
                }
                text="Price Quote"
                classNameText={isInView ? 'opacity' : ''}
                delayText="1.4s"
                delaySVG="1.4s"
                delayFill="1.4s"
                delayInfinite="1s"
              />
              <PathDiagram
                path={
                  <svg
                    width="181"
                    height="87"
                    viewBox="0 0 181 87"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="custom-line"
                  >
                    <path
                      d="M3.60306 2.38856V18.3086C3.60306 29.3542 12.5574 38.3086 23.6031 38.3086H160C171.046 38.3086 180 47.2629 180 58.3086V86.2211"
                      stroke="url(#paint0_linear_3_20022)"
                      stroke-linecap="round"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M6.46875 5.73633H0.734375L3.59347 0.739656L6.46875 5.73633Z"
                      fill="white"
                      className={isInView ? 'fill' : ''}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3_20022"
                        x1="180"
                        y1="193.5"
                        x2="4"
                        y2="2.49999"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                    </defs>
                  </svg>
                }
                text="Data collect"
                classNameText={isInView ? 'opacity' : ''}
                delayText="1.4s"
                delaySVG="1.4s"
                delayFill="1.4s"
                delayInfinite="1.3s"
              />
            </DiagramChange>
            <DiagramForms
              data={diagramAggregators}
              title="DEX Aggregators"
              className={isInView ? 'appear' : ''}
              delay="1.7s"
            />
            <DiagramChange className="custom-2" ref={container}>
              <PathDiagram
                path={
                  <svg
                    width="6"
                    height="46"
                    viewBox="0 0 6 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 43.002L3 0.984375"
                      stroke="url(#paint0_linear_3_2011)"
                      stroke-linecap="round"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M5.86719 40.5039H0.132812L2.9919 45.5006L5.86719 40.5039Z"
                      fill="white"
                      className={isInView ? 'fill' : ''}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3_2011"
                        x1="2.5"
                        y1="43.002"
                        x2="2.5"
                        y2="0.984375"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                }
                text="Traders"
                classNameText={isInView ? 'opacity' : ''}
                delayText="1.8s"
                delaySVG="1.8s"
                delayFill="1.8s"
                delayInfinite="1.8s"
              />
              <PathDiagram
                path={
                  <svg
                    width="6"
                    height="44"
                    viewBox="0 0 6 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 2.99805L3 43.1738"
                      stroke="url(#paint0_linear_3_205)"
                      stroke-linecap="round"
                      className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                    />
                    <path
                      d="M5.86719 5.49609H0.132812L2.9919 0.499422L5.86719 5.49609Z"
                      fill="white"
                      className={isInView ? 'fill' : ''}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3_205"
                        x1="3.5"
                        y1="2.99805"
                        x2="3.5"
                        y2="43.1738"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                }
                text="Liquidity"
                classNameText={isInView ? 'opacity' : ''}
                reverse={true}
                delayText="1.8s"
                delaySVG="1.8s"
                delayFill="1.8s"
                delayInfinite="2s"
              />
            </DiagramChange>
            <DiagramBottom>
              <DiagramBottomForm>
                <DiagramForms
                  data={diagramAmm}
                  title="AMM"
                  className={isInView ? 'appear' : ''}
                  delay="2.2s"
                />
                <DiagramForms
                  data={diagramOrderBook}
                  title="ORDER BOOK"
                  className={isInView ? 'appear' : ''}
                  delay="2.2s"
                />
                <DiagramBottomWrap>
                  <p className={isInView ? 'text' : ''}>DEX</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="830"
                    height="10"
                    viewBox="0 0 830 10"
                    fill="none"
                  >
                    <path
                      d="M1 0.496094C1 5.14845 4.77148 8.91992 9.42383 8.91992H820.576C825.229 8.91992 829 5.14845 829 0.496094V0.496094H1V0.496094Z"
                      stroke="url(#paint0_linear_7909_37532)"
                      className={isInView ? 'line' : ''}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_7909_37532"
                        x1="415"
                        y1="8.91992"
                        x2="415"
                        y2="0.496094"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </DiagramBottomWrap>
              </DiagramBottomForm>
            </DiagramBottom>
            <DiagramFinal>
              <div>
                <PathDiagram
                  path={
                    <svg
                      width="6"
                      height="44"
                      viewBox="0 0 6 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 2.99805L3 43.1738"
                        stroke="url(#paint0_linear_3_205)"
                        stroke-linecap="round"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M5.86719 5.49609H0.132812L2.9919 0.499422L5.86719 5.49609Z"
                        fill="white"
                        className={isInView ? 'fill' : ''}
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_3_205"
                          x1="3.5"
                          y1="2.99805"
                          x2="3.5"
                          y2="43.1738"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" />
                          <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                  text="Tokens"
                  classNameText={isInView ? 'opacity' : ''}
                  delayText="2s"
                  delaySVG="2s"
                  delayFill="2s"
                  delayInfinite="2s"
                />
                <BlockDiagram
                  text="Liquidity Provider"
                  icon="/assets/images/icon_block_1.svg"
                  delay="3s"
                  className={isInView ? 'opacity' : ''}
                />
              </div>
              <div>
                <PathDiagram
                  path={
                    <svg
                      width="6"
                      height="44"
                      viewBox="0 0 6 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 2.99805L3 43.1738"
                        stroke="url(#paint0_linear_3_205)"
                        stroke-linecap="round"
                        className={isInView && !infinite ? 'line' : infinite ? 'infinite' : ''}
                      />
                      <path
                        d="M5.86719 5.49609H0.132812L2.9919 0.499422L5.86719 5.49609Z"
                        fill="white"
                        className={isInView ? 'fill' : ''}
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_3_205"
                          x1="3.5"
                          y1="2.99805"
                          x2="3.5"
                          y2="43.1738"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" />
                          <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                  text="Orders"
                  classNameText={isInView ? 'opacity' : ''}
                  delayText="2.5s"
                  delaySVG="2.5s"
                  delayFill="2.5s"
                  delayInfinite="2.3s"
                />
                <BlockDiagram
                  text="Traders"
                  icon="/assets/images/icon_block_1.svg"
                  delay="3s"
                  className={isInView ? 'opacity' : ''}
                />
              </div>
            </DiagramFinal>
          </>
        ) : (
          <figure>
            <img src="/assets/images/flow_work.png" alt="icon" />
          </figure>
        )}
      </WorkFlowDiagram>
    </WorkContainer>
  )
}

export default Work

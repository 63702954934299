import styled from 'styled-components'

export const BlockTitlePrimaryContainer = styled.div``
export const TitlePrimaryContainer = styled.h1`
  text-align: left;
  margin-bottom: 16px;
  span {
    display: block;
  }
  .title-small {
    color: #ff3556;
    font-family: Tektur;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    padding-left: 10px;
    margin-right: 4px;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 5.047px;
      height: 5.047px;
      border-radius: 1px;
      background: #ff3556;
    }
  }
  .title-big {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 64px;
    font-weight: 500;
    text-transform: capitalize;
  }
  @media screen and (max-width: 768px) {
    .title-big {
      font-size: 14px;
    }
    .title-big {
      font-size: 29px;
    }
  }
`
export const Text = styled.p`
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Wix Madefor Text';
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 40px;
  white-space: pre-line;
  line-height: 24px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 14px;
  }
`

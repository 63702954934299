import { useScramble } from 'use-scramble'
import { ScrambleCustom } from './styled'

interface typeScramble {
  className?: string
  text: string
  linear?: boolean
}

const TextScrambleCommon = ({ linear, text }: typeScramble) => {
  const { ref } = useScramble({
    text,
    speed: 0.6,
    tick: 1,
    step: 1,
    scramble: 4,
    seed: 0,
  })
  return <ScrambleCustom className={linear ? 'linear' : 'normal'} ref={ref} />
}

export default TextScrambleCommon

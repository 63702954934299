import styled from 'styled-components'

export const HeaderContainer = styled.div`
  padding: 12px 0;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
`
export const HeaderWrapper = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const HeaderLogo = styled.figure`
  height: 21px;
`
export const PriceToken = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
  figure {
    display: flex;
    width: 22px;
    height: 22px;
    margin-right: 5px;
    img {
      width: 100%;
    }
  }
  p {
    font-family: Tektur;
    color: #f7f7f8;
    font-size: 16px;
    @media screen and (max-width: 550px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 550px) {
    text-align: end;
  }
`

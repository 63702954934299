import styled from 'styled-components'

export const KeyvisualContainer = styled.section`
  min-height: 100vh;
  position: relative;
  padding-top: 72px;
  overflow: hidden;
  margin-bottom: 100px;
  &:before,
  &:after {
    position: absolute;
    content: '';
    background: center / cover no-repeat;
    width: 801px;
    height: 1252px;
    pointer-events: none;
    top: 0;
  }
  &:before {
    background-image: url('/assets/images/keyvisual_bg_01.svg');
    left: 0;
  }
  &:after {
    background-image: url('/assets/images/keyvisual_bg_02.svg');
    right: 0;
  }
  @media screen and (max-width: 767px) {
    min-height: unset;
    margin-bottom: 50px;
    &::before,
    &::after {
      height: 100%;
      width: 100%;
    }
  }
`
export const KeyvisualContainerInner = styled.div`
  position: relative;
  height: 100%;
  z-index: 1;
  padding-top: 84px;
  /* &:before, */
  &:after {
    width: 100%;
    content: '';
    position: absolute;
    background: center / cover no-repeat;
    pointer-events: none;
  }
  /* &:before {
    height: 82%;
    top: -72px;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/assets/images/keyvisual_bg_03.png');
    z-index: 2;
  } */
  &:after {
    height: 615px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/assets/images/keyvisual_bg_04.png');
  }
  @media screen and (max-width: 768px) {
    &:before {
      height: 100%;
      top: 0;
    }
  }
`
export const KeyvisualWrapper = styled.div`
  .item-icon {
    margin-right: 70px;
  }
  .wrapper-inner {
    position: relative;
  }
  .content-image {
    text-align: center;
    margin-bottom: 70px;
    img {
      position: relative;
      z-index: 3;
    }
  }
  .content-image-inner {
    border-radius: 616px;
    background: rgba(3, 1, 1, 0.75);
    filter: blur(100px);
    /* backdrop-filter: blur(150px); */
    width: 616px;
    height: 616px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    margin-left: -308px;
    top: 0;
    z-index: 2;
    pointer-events: none;
  }
  @media screen and (max-width: 768px) {
    .content-image {
      width: 50%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .item-icon {
      width: 30%;
      margin-right: 0px;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
`
export const ListIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  margin-top: -48px;
  &.list-left {
    left: -70px;
    width: 50%;
  }
  &.list-right {
    right: -40px;
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    margin-top: -28px;
  }
`
export const KeyvisualBlockContent = styled.div`
  max-width: 1137px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 2;
`
export const KeyvisualTitle = styled.h1`
  color: #fff;
  font-family: 'Wix Madefor Text';
  font-size: 77.527px;
  text-transform: capitalize;
  padding-right: 60px;
  margin-bottom: 10px;
  span {
    text-align: center;
    font-family: Tektur;
    font-size: 71.488px;
    font-weight: 500;
    text-transform: capitalize;
    background: linear-gradient(90deg, #ff3556 0%, #ff63a1 26.14%, #ffd779 45.64%, #fff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 768px) {
    padding-right: 0;
    font-size: 30px;
    span {
      font-size: 32px;
    }
  }
`
export const KeyvisualDocs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 53px;
  .content-left {
    display: flex;
    align-items: flex-end;
    gap: 12px;
    span {
      line-height: 1;
    }
    .text {
      color: rgba(255, 255, 255, 0.5);
      font-family: 'Wix Madefor Text';
      font-size: 20px;
      font-weight: 500;
      text-transform: capitalize;
      position: relative;
    }
    .dot {
      width: 5px;
      height: 5px;
      border-radius: 1px;
      background: #eb405f;
    }
  }
  .content-middle {
    width: 52%;
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  }
  .content-right {
    display: flex;
    align-items: center;
    gap: 9px;
    flex-shrink: 0;
    a {
      padding: 14px 12px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.15);
      color: #fff;
      font-family: 'Wix Madefor Text';
      font-size: 16px;
      line-height: 1;
      span {
        position: relative;
        padding-left: 22px;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -8px;
          background: center / contain no-repeat;
          width: 16px;
          height: 17px;
        }
      }
      &.is-docs {
        span {
          &:before {
            background-image: url('/assets/images/keyvisual_icn_docs.svg');
          }
        }
      }
      &.is-deck {
        span {
          &:before {
            background-image: url('/assets/images/keyvisual_icn_deck.svg');
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 30px;
    .content-left {
      margin-bottom: 20px;
    }
    .content-middle {
      display: none;
    }
  }
`
export const KeyvisualParner = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url('/assets/images/keyvisual_bg_05.png') center / cover no-repeat;
    z-index: 2;
    pointer-events: none;
  }
  .content-partner {
    position: relative;
    width: 249px;
    height: 109px;
    img {
      transition: opacity 0.26s ease;
      position: absolute;
    }
    .partner-active {
      opacity: 0;
    }
    &:hover {
      .partner-active {
        opacity: 1;
      }
      .partner-normal {
        opacity: 0;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    z-index: 2;
    .content-partner {
      text-align: center;
    }
  }
`

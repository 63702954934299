import styled from 'styled-components'

export const WrapJoinOut = styled.div`
  width: 100%;
  position: relative;
  border-radius: 16px;
  padding-bottom: 70px;
  border: 1px;
  background: #000;
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url('/assets/images/bg-join-c.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`
export const ShowJoinOut = styled.div`
  width: 100%;
  padding: 1px;
  border-radius: 16px;
  border-radius: 16px;
  background: linear-gradient(180deg, #b2a959 -40%, #991e48 100%),
    #ccc588 -669.586px -348.679px / 108.215% 138.47% no-repeat;
  margin: 100px 0 80px;
  @media screen and (max-width: 767px) {
    margin: 50px 0;
  }
`
export const TitleJoinOut = styled.div`
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
  h1 {
    color: #fff;
    text-align: center;
    font-family: 'Wix Madefor Text';
    font-size: 56px;
    font-weight: 500;
    text-transform: capitalize;
  }
  p {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: 'Wix Madefor Text';
    font-size: 16px;
    text-transform: capitalize;
    line-height: 24px;
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 28px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }
`
export const WrapButtonLink = styled.div`
  position: relative;
  gap: 8px;
  margin-top: 30px;
`

export const ButtonLink = styled.a`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.15);
  cursor: pointer;
  width: fit-content;
  p {
    color: #fff;
    font-family: 'Wix Madefor Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
  }
`

import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'

const MotionParallaxItem = ({ children, index }: { children: React.ReactNode; index: number }) => {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0.8 1', '1 1'],
  })

  const y = useTransform(scrollYProgress, [0, 1], [100, 0])
  // const opacity = useTransform(scrollYProgress, [0, 1], [0, 1])

  return (
    <motion.div
      ref={ref}
      style={{
        y,
        // opacity,
        position: 'sticky',
        top: '100px',
        zIndex: index + 1,
        marginBottom: '50px',
      }}
      className="item-card"
    >
      {children}
    </motion.div>
  )
}

export default MotionParallaxItem

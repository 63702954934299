import { ImgHTMLAttributes } from 'react'
import { getIconPath, IconType } from '../../icons'

interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: IconType
}

export const AutoImage: React.FC<IProps> = function AutoImage({ src, ...props }) {
  return <img {...props} alt={props.alt} src={getIconPath(src)} />
}

import { useScramble } from 'use-scramble'
import { BlockContentSM, ButtonCommon } from '../../Layout/styled'
import { FrameChatDefAi, TitleDefAi, WrapBgDefAi, WrapContentDefAi, WrapDefAi } from './styled'
import FrameChat from './Chat'
import { AutoImage } from '../ImageCommon'
import { useContext, useEffect, useRef, useState } from 'react'
import { useInView } from 'framer-motion'
import { ContextProviderWrapper } from '../Context'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function DefAi() {
  const { ref } = useScramble({
    text: 'Kibble DeFAI',
    speed: 0.6,
    tick: 1,
    step: 1,
    scramble: 4,
    seed: 0,
  })
  const { isMobile } = useContext(ContextProviderWrapper)!
  const container: any = useRef(null)
  const boxRef = useRef<HTMLDivElement>(null)
  const [showContent, setShowContent] = useState(0)
  const chatContainerRef = useRef(null)
  const isInView = useInView(chatContainerRef, {
    once: true,
    margin: '0px 0px -40% 0px',
  })

  useEffect(() => {}, [])

  useEffect(() => {
    if (!boxRef.current || isMobile) return

    gsap.registerPlugin(ScrollTrigger)

    const ctx = gsap.context(() => {
      setTimeout(() => {
        ScrollTrigger.create({
          trigger: boxRef.current,
          start: 'top 0%',
          end: 'bottom 0%',
          pin: true,
          scrub: false,
          markers: false,
        })
      }, 100)
      ScrollTrigger.create({
        trigger: boxRef.current,
        start: 'top 0%',
        once: false,
        onUpdate: (self) => {
          if (self.progress === 0) {
            setShowContent(0)
          } else {
            const newStep = Math.floor(self.progress * 8) + 1
            setShowContent((prev) => (prev !== newStep ? newStep : prev))
          }
        },
      })
    }, boxRef)

    return () => ctx.revert()
  }, [])

  useEffect(() => {
    if (isMobile && isInView && typeof setShowContent === 'function') {
      let index = 1
      const intervalChat = setInterval(() => {
        index++
        setTimeout(() => {
          setShowContent(index)
        }, 1500)
        if (index > 7) clearInterval(intervalChat)
      }, 2000)

      return () => clearInterval(intervalChat)
    }
  }, [isMobile, isInView, setShowContent])

  return (
    <WrapDefAi ref={boxRef}>
      <WrapBgDefAi>
        <BlockContentSM>
          <WrapContentDefAi className="row-center" ref={container}>
            <TitleDefAi>
              <div className="img-box">
                <AutoImage src="img-corner-box.png" width={'100%'} />
              </div>
              <h2 className="row-left" data-aos="fade-up">
                <div className="dot"></div>
                How it work
              </h2>
              <h1 ref={ref} data-aos="fade-up"></h1>
              <p className="des" data-aos="fade-up">
                Kibble DeFAI is crafted for Web3 users,
                <br /> offering real-time market updates, seamless
                <br /> trading, efficient liquidity management, and
                <br /> optimal pricing for every trade
              </p>
              <ButtonCommon to="/" data-aos="fade-up">
                <p>
                  <span>START NOW</span>
                  <span>START NOW</span>
                </p>
              </ButtonCommon>
            </TitleDefAi>
            <FrameChatDefAi className="row-right" ref={chatContainerRef}>
              <FrameChat showContent={showContent} />
            </FrameChatDefAi>
          </WrapContentDefAi>
        </BlockContentSM>
      </WrapBgDefAi>
    </WrapDefAi>
  )
}

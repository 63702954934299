import styled from 'styled-components'

export const PathDiagramContainer = styled.div<{
  delayText?: any
  delaySVG?: any
  delayFill?: any
  delayInfinite?: any
}>`
  width: max-content;
  p {
    position: relative;
    top: -3px;
    color: #fff;
    text-align: center;
    font-family: 'Wix Madefor Text';
    font-size: 12px;
    transition: all 0.25s linear;
    opacity: 0;
  }
  .start-line {
    position: relative;
    top: -10px;
    .spread-point {
      position: absolute;
      right: -20px;
      top: 50%;
      z-index: 5;
      margin-top: -22px;
      opacity: 0;
      .item {
        opacity: 0;
      }
      &_1 {
        animation: SpreadOval 0.9s linear infinite;
        animation-delay: 3s;
      }
      &_2 {
        animation: SpreadOval 0.8s linear infinite;
        animation-delay: 2.5s;
      }
      &_3 {
        animation: SpreadOval 0.7s linear infinite;
        animation-delay: 2s;
      }
      &_4 {
        animation: SpreadOval 0.6s linear infinite;
        animation-delay: 1.5s;
      }
      &_5 {
        animation: SpreadOvalNode 0.5s linear forwards;
        animation-delay: 1s;
      }
    }
  }
  .custom-line {
    position: relative;
    top: -10px;
    z-index: 5;
  }
  svg {
    rect {
      position: relative;
      opacity: 0;
    }
    path {
      stroke-dasharray: 0 0;
      opacity: 0;
    }
  }
  .line {
    opacity: 0;
    animation: drawLine 1s linear forwards;
    animation-delay: ${({ delaySVG }) => delaySVG};
  }
  .opacity {
    opacity: 0;
    animation: opacityText 1s linear forwards;
    animation-delay: ${({ delayText }) => delayText};
  }
  .fill {
    animation: fillLine 1s linear forwards;
    animation-delay: ${({ delayFill }) => delayFill};
  }
  path {
    &.infinite {
      opacity: 1;
      animation: infiniteLine1 2s linear infinite;
      animation-delay: ${({ delayInfinite }) => delayInfinite};
    }
    &.infinite-fill {
      opacity: 1;
      animation: fillLine 1s linear infinite;
      animation-delay: ${({ delayInfinite }) => delayInfinite};
    }
  }
  @keyframes opacityText {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes drawLine {
    0% {
      stroke-dasharray: 0 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      stroke-dasharray: 500 500;
      opacity: 1;
    }
  }
  @keyframes infiniteLine1 {
    from {
      stroke-dasharray: 0 200;
    }
    to {
      stroke-dasharray: 750 750;
    }
  }
  @keyframes fillLine {
    from {
      fill: transparent;
    }
    to {
      fill: #fff;
      opacity: 1;
    }
  }
  @keyframes SpreadOval {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes SpreadOvalNode {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

import styled from 'styled-components'

export const SkeletonFormItem = styled.div`
  display: flex;
  gap: 10px;
  margin-right: auto;
  align-items: center;
  .ant-skeleton {
    width: 80px;
  }
  .ant-skeleton-paragraph li {
    border-radius: 2px !important;
    box-shadow: 0px 3.765px 23.247px 0px rgba(255, 255, 255, 0.25) inset;
  }
`
export const IconActive = styled.div<{ delay?: any }>`
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0px 0px 43.765px #ff3556) drop-shadow(0px 0px 58.353px #ff3556);
    background: radial-gradient(55.88% 55.88% at 50% 50%, #ff394a 0%, #fff 100%);
    border-radius: 50%;
    animation: backgroundRadial 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    animation-delay: ${({ delay }) => delay};
  }
  @keyframes backgroundRadial {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

import { AutoImage } from '../../components/ImageCommon'
import { useMexcPrice } from '../../utils/getPriceKIB'
import { ButtonCommon } from '../styled'
import { HeaderContainer, HeaderLogo, HeaderWrapper, PriceToken } from './styled'

const Header = () => {
  const { price } = useMexcPrice()
  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLogo data-aos="fade-up" data-aos-duration="1000">
          <AutoImage src="kibble_logo.png" width={112} height={21} loading="lazy" alt="Kibble" />
        </HeaderLogo>
        {price && (
          <PriceToken>
            <figure>
              <img src="/assets/images/iconKIB.png" alt="icon" />
            </figure>
            <p>${price || 0.0}</p>
          </PriceToken>
        )}
        <ButtonCommon
          to="https://app.kibble.exchange/"
          target="_blank"
          rel="noreferrer"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <p>
            <span>LAUNCH APP</span>
            <span>LAUNCH APP</span>
          </p>
        </ButtonCommon>
      </HeaderWrapper>
    </HeaderContainer>
  )
}

export default Header

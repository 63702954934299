import { useContext } from 'react'
import { BlockChat, ChatContainer, ChatWrapper, FrameShowChat, Listfunction } from './styled'
import Guild from '../Guild'
import { ContextProviderWrapper } from '../../Context'

const FrameChat = ({ showContent, setShowContent }: any) => {
  const { isMobile } = useContext(ContextProviderWrapper)!

  return (
    <ChatContainer className="form-chat">
      <ChatWrapper
        style={{
          paddingTop: showContent === 1 ? '0px' : isMobile ? '15px' : '30px',
        }}
      >
        {showContent === 0 ? (
          <div className="block-top">
            <h2 className="title-chat">
              <span>Hi there</span> What would you like to know?
            </h2>
            <p className="text-chat">
              How I can help you today? You can start with some of prompt below or use your own to
              begin
            </p>
            <Listfunction>
              {listFunction.map((item, index) => (
                <li key={index} className="item-function">
                  <img
                    src={item.icon}
                    width={45}
                    height={42}
                    loading="lazy"
                    alt={item.title}
                    className="icon"
                  />
                  <h3 className="title">{item.title}</h3>
                  <p className="text">{item.text}</p>
                </li>
              ))}
            </Listfunction>
          </div>
        ) : (
          <FrameShowChat>
            <Guild showContent={showContent} setShowContent={setShowContent} />
          </FrameShowChat>
        )}
        <BlockChat>
          <div className="block-textarea">
            <textarea placeholder="Ask Kibble AI something..." />
            <img
              className={`icon-chat active`}
              src="/assets/images/icn_chat.svg"
              width={48}
              height={49}
              loading="lazy"
              alt="Chat"
            />
          </div>
          <div className="block-form">
            <ul className="list-form">
              {itemForm.map((item, index) => (
                <li key={index}>
                  <img src={item.icon} width={14} height={14} loading="lazy" alt={item.des} />
                  <span>{item.des}</span>
                </li>
              ))}
            </ul>
            <div className="block-voice">
              <img
                src="/assets/images/icn_voice.svg"
                width={16}
                height={16}
                loading="lazy"
                alt="Voice"
              />
            </div>
          </div>
        </BlockChat>
      </ChatWrapper>
    </ChatContainer>
  )
}

const listFunction = [
  {
    icon: '/assets/images/img-swap.png',
    title: 'Swap',
    text: 'Swap 5 USDC to USDT on AVALANCHE',
  },
  {
    icon: '/assets/images/img-swap.png',
    title: 'Staking',
    text: 'Show me prompt example for token staking',
  },
  {
    icon: '/assets/images/img-swap.png',
    title: 'Cross-Chain Swap',
    text: 'Show me prompt example for Cross-Chain Swap transaction',
  },
]

const itemForm = [
  {
    icon: '/assets/images/icn_form_01.svg',
    des: 'Swap',
  },
  {
    icon: '/assets/images/icn_form_02.svg',
    des: 'Bridge',
  },
]

export default FrameChat

import { Link } from 'react-router-dom'
import { AutoImage } from '../../components/ImageCommon'
import {
  KeyvisualBlockContent,
  KeyvisualContainer,
  KeyvisualContainerInner,
  KeyvisualDocs,
  KeyvisualParner,
  KeyvisualTitle,
  KeyvisualWrapper,
  ListIcon,
} from './styled'
import { useScramble } from 'use-scramble'
import Marquee from 'react-fast-marquee'
import { useContext } from 'react'
import { ContextProviderWrapper } from '../../components/Context'
import { TypeAnimation } from 'react-type-animation'

const Keyvisual = () => {
  const { ref, replay } = useScramble({ text: 'Meta Aggrerrator', speed: 0.6 })
  const { isMobile } = useContext(ContextProviderWrapper)!
  return (
    <KeyvisualContainer>
      <KeyvisualContainerInner>
        <KeyvisualWrapper>
          <div className="wrapper-inner">
            <div className="content-image">
              <AutoImage
                src="keyisual_img_01.png"
                width={466}
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
              />
            </div>
            <div className="content-image-inner"></div>
            <ListIcon
              className="list-left"
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-duration="1000"
            >
              <Marquee autoFill={true}>
                {listIcon.map((item: any, index) => (
                  <div className="item-icon" key={index}>
                    <AutoImage src={item.icon} width={93} height={93} loading="eager" alt="icon" />
                  </div>
                ))}
              </Marquee>
            </ListIcon>
            <ListIcon
              className="list-right"
              data-aos="fade-up"
              data-aos-delay="1300"
              data-aos-duration="1000"
            >
              <Marquee direction={'right'} autoFill={true}>
                {listIcon.map((item: any, index) => (
                  <div className="item-icon" key={index}>
                    <AutoImage src={item.icon} width={93} height={93} loading="lazy" alt="icon" />
                  </div>
                ))}
              </Marquee>
            </ListIcon>
          </div>
          <KeyvisualBlockContent>
            <KeyvisualTitle
              data-aos="fade-up"
              data-aos-delay={isMobile ? 0 : 1800}
              data-aos-duration="1000"
            >
              Forging Tomorrow's DeFi with
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed once, initially
                  1000,
                  ' Meta Aggrerator',
                  1000,
                  ' AI Precision',
                  1000,
                  ' Cross-chain',
                ]}
                wrapper="span"
                speed={40}
                repeat={Infinity}
              />
            </KeyvisualTitle>
            <KeyvisualDocs data-aos="fade-up" data-aos-duration="1000">
              <div className="content-left">
                <span className="text">Aggregate</span>
                <span className="dot"></span>
                <span className="text">Analyze</span>
                <span className="dot"></span>
                <span className="text">Amplify</span>
              </div>
              <div className="content-middle"></div>
              <div className="content-right">
                <Link className="is-docs trans" to="/" target="_blank">
                  <span>Docs</span>
                </Link>
                <Link className="is-deck trans" to="/" target="_blank">
                  <span>Pitchdeck</span>
                </Link>
              </div>
            </KeyvisualDocs>
          </KeyvisualBlockContent>
          <KeyvisualParner data-aos="fade-up" data-aos-duration="1000">
            <Marquee autoFill={true} pauseOnHover={true}>
              {listParner.map((item: any, index) => (
                <div className="content-partner" key={index}>
                  <AutoImage className="partner-nomal" key={index} src={item.image} width={249} />
                  <AutoImage
                    className="partner-active"
                    key={index}
                    src={item.imageActive}
                    width={249}
                  />
                </div>
              ))}
            </Marquee>
          </KeyvisualParner>
        </KeyvisualWrapper>
      </KeyvisualContainerInner>
    </KeyvisualContainer>
  )
}

const listIcon = [
  {
    icon: 'keyvisual_icon_01.png',
  },
  {
    icon: 'keyvisual_icon_01.png',
  },
  {
    icon: 'keyvisual_icon_01.png',
  },
  {
    icon: 'keyvisual_icon_01.png',
  },
]

const listParner = [
  {
    image: 'keyvisual_parner_01.png',
    imageActive: 'keyvisual_parner_01.png',
  },
  {
    image: 'keyvisual_parner_02.png',
    imageActive: 'keyvisual_parner_02_active.png',
  },
  {
    image: 'keyvisual_parner_03.png',
    imageActive: 'keyvisual_parner_03_active.png',
  },
  {
    image: 'keyvisual_parner_04.png',
    imageActive: 'keyvisual_parner_04_active.png',
  },
  {
    image: 'keyvisual_parner_05.png',
    imageActive: 'keyvisual_parner_05_active.png',
  },
  {
    image: 'keyvisual_parner_06.png',
    imageActive: 'keyvisual_parner_06_active.png',
  },
  {
    image: 'keyvisual_parner_07.png',
    imageActive: 'keyvisual_parner_07_active.png',
  },
  {
    image: 'keyvisual_parner_08.png',
    imageActive: 'keyvisual_parner_08_active.png',
  },
  {
    image: 'keyvisual_parner_10.png',
    imageActive: 'keyvisual_parner_10_active.png',
  },
  {
    image: 'keyvisual_parner_11.png',
    imageActive: 'keyvisual_parner_11_active.png',
  },
  {
    image: 'keyvisual_parner_12.png',
    imageActive: 'keyvisual_parner_12_active.png',
  },
  {
    image: 'keyvisual_parner_13.png',
    imageActive: 'keyvisual_parner_13_active.png',
  },
  {
    image: 'keyvisual_parner_14.png',
    imageActive: 'keyvisual_parner_14_active.png',
  },
]

export default Keyvisual

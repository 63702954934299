import { BlockContent, WrapContainer } from '../../Layout/styled'
import { AutoImage } from '../ImageCommon'
import { ButtonLink, ShowJoinOut, TitleJoinOut, WrapButtonLink, WrapJoinOut } from './styled'

export default function JoinOurCommunity() {
  return (
    <WrapContainer>
      <BlockContent>
        <ShowJoinOut data-aos="fade-up">
          <WrapJoinOut>
            <div className="img-k row-center">
              <AutoImage src="img-k.png" width={200} />
            </div>
            <TitleJoinOut>
              <h1>Join Our Community</h1>
              <p>
                Kibble reshapes DeFi with its AI-powered platform on Avalanche C-Chain, enhances
                DeFi with seamless data aggregation, precise trend analysis, and amplified financial
                potential
              </p>
              <WrapButtonLink className="row-center">
                <ButtonLink
                  className="row-center"
                  href="https://t.me/KibbleOfficial"
                  target="_blank"
                >
                  <AutoImage src="icon_telegram.svg" width={16} />
                  <p>Telegram</p>
                </ButtonLink>
                <ButtonLink
                  className="row-center"
                  href="https://discord.com/invite/kibbleai"
                  target="_blank"
                >
                  <AutoImage src="icon_discord_01.svg" width={16} />
                  <p>Discord</p>
                </ButtonLink>
              </WrapButtonLink>
            </TitleJoinOut>
          </WrapJoinOut>
        </ShowJoinOut>
      </BlockContent>
    </WrapContainer>
  )
}

import { useScramble } from 'use-scramble'
import { PathDiagramContainer } from './styled'

const PathDiagram = ({
  path,
  text,
  reverse,
  classNameText,
  delayText,
  delaySVG,
  delayFill,
  delayInfinite,
}: any) => {
  const { ref: refScramble } = useScramble({ text, speed: 0.4 })
  return (
    <PathDiagramContainer
      delayText={delayText}
      delaySVG={delaySVG}
      delayFill={delayFill}
      delayInfinite={delayInfinite}
    >
      {!reverse ? (
        <>
          {text && <p className={classNameText} ref={refScramble} />}
          {path}
        </>
      ) : (
        <>
          {path}
          {text && <p className={classNameText} ref={refScramble} />}
        </>
      )}
    </PathDiagramContainer>
  )
}

export default PathDiagram

import styled from 'styled-components'
import {
  BlockTitlePrimaryContainer,
  TitlePrimaryContainer,
} from '../../components/TitlePrimary/styled'
import { PathDiagramContainer } from './Diagram/Path/styled'
import { BlockDiagramContainer } from './Diagram/Block/styled'

export const WorkContainer = styled.div`
  max-width: 1388px;
  border-radius: 16px;
  margin: 0 auto 250px;
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
`
export const WorkWrapper = styled.div`
  position: relative;
  ${BlockTitlePrimaryContainer} {
    position: relative;
    ${TitlePrimaryContainer} {
      text-align: center;
      width: max-content;
      margin: 0 auto;
    }
    .title-small {
      width: max-content;
      margin: 0 auto;
      text-align: center;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.35);
      padding: 8px 12px 8px 24px;
      &::before {
        left: 14px;
      }
    }
    .title-big {
      p {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        span {
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
    ${BlockTitlePrimaryContainer} {
      position: relative;
      ${TitlePrimaryContainer} {
        width: auto;
      }
      .title-big {
        p {
          justify-content: center;
          span {
            &:last-child {
              width: auto;
            }
          }
        }
      }
    }
  }
`
export const WorkFlowDiagram = styled.div`
  width: max-content;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    width: auto;
    & > figure {
      width: calc(100% - 10px);
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`
export const DiagramHead = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  left: -88px;
  & > figure {
    position: relative;
    left: -40px;
    transition: all 0.25s linear;
    opacity: 0;
    &.opacity {
      animation: opacityText 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
      animation-delay: 1.5s;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    left: -110px;
  }
`
export const DiagramChange = styled.div`
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 70px;
  &.custom {
    margin: 0 auto -15px;
    margin-top: -62px;
    left: 15px;
  }
  &.custom-2 {
    margin: 0 auto 20px;
    gap: 285px;
    left: -15px;
    ${PathDiagramContainer} {
      p {
        left: -18px;
      }
      &:nth-child(1) {
        p {
          top: 10px;
        }
      }
      &:nth-child(2) {
        position: relative;
        right: -100px;
        p {
          top: -10px;
        }
      }
    }
  }
  ${PathDiagramContainer} {
    p {
      position: relative;
      top: 20px;
    }
  }
`
export const DiagramForm = styled.div<{ delay?: any }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: max-content;
  margin: 0 auto;
  gap: 8px;
  left: 20px;
  border-radius: 4px;
  border: 1px solid #858585;
  backdrop-filter: blur(11px);
  padding: 10px;
  opacity: 0;
  transform: translateY(30px);
  &.appear {
    animation: fadeBlock 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    animation-delay: ${({ delay }) => delay};
    h2 {
      animation: fadeBlock 0.5s linear forwards;
      animation-delay: 1.5s;
    }
    & > div {
      animation: fadeBlock 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    }
  }
  &.loading {
  }
  h2 {
    display: block;
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: Tektur;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(20% - 7px);
    padding: 8px 20px;
    gap: 10px;
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(153, 153, 153, 0.15) 100%
    );
    box-shadow: 0px 3.765px 23.247px 0px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(5px);
    opacity: 0;
    figure {
      border-radius: 50%;
      overflow: hidden;
      width: 26px;
      height: 26px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      color: #fff;
      font-family: 'Wix Madefor Text';
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  @keyframes fadeBlock {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 750px;
    left: 0;
  }
`
export const DiagramBottom = styled.div`
  position: relative;
  left: 40px;
  display: flex;
  align-items: center;
  ${DiagramForm} {
    padding-left: 5px;
    padding-right: 5px;
    width: 410px;
    left: 0;
    & > div {
      width: calc(33.33% - 6px);
      padding-left: 8px;
      padding-right: 8px;
      figure {
        flex-shrink: 0;
      }
    }
  }
  ${PathDiagramContainer} {
    p {
      top: -10px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    left: 0;
  }
`
export const DiagramBottomForm = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
export const DiagramBottomWrap = styled.div`
  position: absolute;
  top: -50px;
  left: 0;
  pointer-events: none;
  svg {
    transform: rotate(180deg);
    path {
      opacity: 0;
      &.line {
        animation: drawLineDex 1s linear forwards;
        animation-delay: 2s;
      }
    }
  }
  p {
    color: #ff3556;
    text-align: center;
    font-family: Tektur;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 0;
    &.text {
      animation: SpreadOvalNode 1s linear forwards;
      animation-delay: 2s;
    }
  }
  @keyframes drawLineDex {
    0% {
      stroke-dasharray: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      stroke-dasharray: 1500 1500;
      opacity: 1;
    }
  }
`
export const DiagramFinal = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 220px;
  left: 15px;
  margin-top: 10px;
  & > div,
  ${PathDiagramContainer} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -5px;
  }
  & > div {
    &:nth-child(2) {
      padding-left: 30px;
    }
  }
  ${BlockDiagramContainer} {
    flex-direction: column;
    min-height: 104px;
    min-width: 184px;
  }
`

import { AppContainer } from './styled'
import './App.css'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { ReactLenis } from '@studio-freight/react-lenis'
import LayoutHome from './Layout'
import LandingPage from './page'
import AOS from 'aos'
import 'aos/dist/aos.css'

const App = () => {
  const { pathname } = useLocation()
  AOS.init()

  return (
    <ReactLenis
      root
      options={{
        duration: pathname === '/' ? 2 : 1,
      }}
    >
      <AppContainer>
        <Routes>
          <Route path="/" element={<LayoutHome />}>
            <Route path="" element={<LandingPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AppContainer>
    </ReactLenis>
  )
}

export default App
